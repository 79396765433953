import React, { Fragment, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import _ from 'lodash'
import PropTypes from 'prop-types'

// COMPONENTS
import ModalSignOut from './ModalSignOut'
import AttachmentPopup from '../common/AttachmentPopup'

// CONSTANTS
import { MAX_SIZE_OF_FILE, MAX_SIZE_OF_FILE_BEFORE_UPLOAD } from 'constants/newBookingConstants'
import { UPLOAD_IMAGE, LOG_OUT_IMAGE, IMAGE_PLACE_HOLDER } from 'constants/imageConstants'

// Utils COMMON
import { Utils } from 'utils/Utils'
import { hideLoading, readURL, showLoading } from 'assets/javascripts/webapp-v2/common'
import toastr from 'utils/toast'
import { currentCustomerActionsCreator } from 'store/toolkit/currentCustomer/currentCustomer.reducer'
import { useTranslation } from 'react-i18next'
import CustomerAPI from 'api/customers'

const checkAndUploadFile = (input, setIsShowImageErrorUpload) => {
  if(!input.files[0]) return
  const reader = new FileReader()
  const file = input.files[0]
  const image = new Image()
  const { size, name } = file
  const typeFile = file.type.split('/')
  reader.readAsDataURL(file)
  if (typeFile[0] === 'image' && size < MAX_SIZE_OF_FILE_BEFORE_UPLOAD) {
    reader.onloadend = () => {
      image.src = reader.result
      image.onload = () => {
        const compressedImage = Utils.compressImage(image, image.width, image.height, Utils.getQualityImage(size))
        const fileCompressImage = Utils.dataURLtoFile(compressedImage, name)
        if (fileCompressImage.size > MAX_SIZE_OF_FILE) {
          setIsShowImageErrorUpload(true)
          input.value = ''
        } else {
          readURL(input, 'upload_image_view')
        }
      }
    }
  } else if (size > MAX_SIZE_OF_FILE) {
    setIsShowImageErrorUpload(false)
    setIsShowImageErrorUpload(true)
    input.value = ''
  }
}

const generateFormData = (currentCustomer, params, t) => {
  let isValid = false
  const isHavePassword = !_.isEmpty(params.password) || !_.isEmpty(params.password_confirmation)
  const isEmptyPassword = _.isEmpty(params.password) && _.isEmpty(params.password_confirmation)

  if (_.isEmpty(params.password) && _.isEmpty(params.password_confirmation)) {
    delete params.current_password
    delete params.password
    delete params.password_confirmation
  }

  const formData = new FormData()
  Object.keys(params).forEach((key) => {
    if (currentCustomer[key] !== params[key]) {
      formData.append(key, params[key])
    }
  })
  if (params.avatar_square) {
    formData.append('avatar', params.avatar_square)
  }
  toastr.remove()
  if (isHavePassword) {
    if (_.isEmpty(params.current_password)) {
      toastr.info(t('error.messages.password_not_blank'))
      return
    }
    isValid = Utils.isValidPassword(params.password, params.password_confirmation)
  }
  if (isValid || isEmptyPassword) return formData
  return 
}

const formFieldWithIcon = (labelName, refName, name, checked, action) => (
  <div className="FormGroup">
    <label className="FormGroup-label capitalize">{labelName}</label>
    <div className="Input">
      <input type={checked ? '' : 'password'} ref={refName} />
      <label className="Input-Icon">
        <input type="checkbox" nam={name} style={{ display: 'none' }} checked={checked} />
        <i className={`b material-icons Icon ${checked ? 'green' : ''}`} onClick={() => action()}>
          visibility
        </i>
      </label>
    </div>
  </div>
)

const formField = (labelName, refName, name, defaultValue, disabled = false) => (
  <div className="FormGroup">
    <label className="FormGroup-label capitalize">{labelName}</label>
    <div className={`Input ${disabled ? 'disabled' : ''}`}>
      <div className="header">
        <input autoComplete={false} type="text" name={name} ref={refName} defaultValue={defaultValue} />
      </div>
    </div>
  </div>
)

const FormEditUser = (props) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const firstNameRef = useRef()
  const lastNameRef = useRef()
  const emailRef = useRef()
  const phoneRef = useRef()
  const companyNameRef = useRef()
  const curPasswordRef = useRef()
  const newPasswordRef = useRef()
  const confirmPasswordRef = useRef()

  const { currentCustomer, setIsShowEdit } = props
  const [isShowPopupLogout, setIsShowPopupLogout] = useState(false)
  const [checkStatus, setCheckStatus] = useState({
    curPassChecked: false,
    newPassChecked: false,
    confirmPassChecked: false,
  })
  const [isShowImageErrorUpload, setIsShowImageErrorUpload] = useState(false)

  if (_.isEmpty(currentCustomer)) {
    return null
  }

  const {
    avatar_square: avatarSquare,
    country_code: countryCode,
    first_name: firstName,
    last_name: lastName,
    email,
    phone,
    business_name: businessName,
  } = currentCustomer

  const showDialogUploadFile = () => {
    const input = document.getElementById('upload_image')
    if (input) {
      input.click()
    }
  }

  const handleUpdateUser = async () => {
    const photo = document.getElementById('upload_image').files[0]
    const params = {
      avatar_square: photo,
      first_name: firstNameRef.current.value,
      last_name: lastNameRef.current.value,
      email: emailRef.current.value,
      phone: phoneRef.current.value,
      business_name: companyNameRef.current.value,
      current_password: curPasswordRef.current.value,
      password: newPasswordRef.current.value,
      password_confirmation: confirmPasswordRef.current.value
    }
    
    const formData = generateFormData(currentCustomer, params, t)
    if(!formData) return
    showLoading()
    const response = await CustomerAPI.updateCustomer(formData)
    hideLoading()
    const data = _.get(response, 'data.object', {})
    const error = _.get(response, 'data.error', '')
    if (!_.isEmpty(error)) return Utils.showToastrMessage('info', error)
    if (_.isEmpty(data)) return null
    Utils.showToastrMessage('success', t('success.messages.updated'))
    dispatch(currentCustomerActionsCreator.updateCustomer(data))
    setIsShowEdit(false)
  }

  const handleCheckStatus = (name, value) => setCheckStatus({ ...checkStatus, [name]: value })

  const renderAvatar = () => (
    <Fragment>
      <label className="xxb rounded Avatar flex-column" htmlFor="upload_image">
        <input
          style={{ display: 'none' }}
          type="file"
          name="customer[avatar]"
          id="upload_image"
          onChange={(e) => checkAndUploadFile(e.target, setIsShowImageErrorUpload)}
        />
        <img
          alt='avatar'
          className="ui image rounded"
          src={avatarSquare === 'missing.png' ? IMAGE_PLACE_HOLDER : avatarSquare}
          width="100"
          height="100"
          id="upload_image_view"
          onClick={() => showDialogUploadFile()}
        />
        <div className="Avatar-icon">
          <img className="Radius-zero" src={UPLOAD_IMAGE} alt='avatar-icon'/>
        </div>
      </label>
      <div
        className="mt10 flex-center green Dark-Green-text-hover cur-pointer view-popupID escape_popup_visible"
        data-id="Modal-logout-as-admin"
        onClick={() => setIsShowPopupLogout(true)}
      >
        <img className="mr10" height="17" src={LOG_OUT_IMAGE} alt="logout" />
        <span className="default-font">{t('label.log_out')}</span>
      </div>
    </Fragment>
  )

  const renderContent = () => (
    <div className="Box-Content vertical-scroll">
      <div className="Form">
        {renderAvatar()}
        {formField(t('webapp.label.first_name'), firstNameRef, 'first_name', firstName)}
        {formField(t('webapp.label.last_name'), lastNameRef, 'last_name', lastName)}
        {formField(t('webapp.label.email'), emailRef, 'email', email, true)}
        {formField(t('webapp.label.phone'), phoneRef, 'phone', phone, true)}
        {formField(t('webapp.label.business_name'), companyNameRef, 'business_name', businessName)}
        {formFieldWithIcon(
          t('webapp.label.current_password'),
          curPasswordRef,
          'current_password',
          checkStatus.curPassChecked,
          () => handleCheckStatus('curPassChecked', !checkStatus.curPassChecked)
        )}
        {formFieldWithIcon(
          t('webapp.label.new_password'),
          newPasswordRef,
          'new_password',
          checkStatus.newPassChecked,
          () => handleCheckStatus('newPassChecked', !checkStatus.newPassChecked)
        )}
        {formFieldWithIcon(
          t('webapp.label.confirm_new_password'),
          confirmPasswordRef,
          'confirm_new_password',
          checkStatus.confirmPassChecked,
          () => handleCheckStatus('confirmPassChecked', !checkStatus.confirmPassChecked)
        )}
      </div>
    </div>
  )

  const renderPopup = () => (
    <Fragment>
      {isShowPopupLogout && (
        <ModalSignOut
          setIsShowPopupLogout={setIsShowPopupLogout}
          countryCode={countryCode}
        />
      )}
      {isShowImageErrorUpload && <AttachmentPopup fixed='fixed' />}
    </Fragment>
  )

  return (
    <div className="profile RightMenu visible">
      <div className="Box">
        <div className="Box-Head">
          <div className="cancel-edit Box-Head-back">
            <i className="xb material-icons Icon" onClick={() => setIsShowEdit(false)}>
              arrow_back
            </i>
          </div>
          <h3>{t('webapp.label.edit_profile')}</h3>
        </div>
        {renderContent()}
        <div className="Box-Actions">
          <button className="gray Button" type="button" onClick={() => setIsShowEdit(false)}>
            {t('webapp.action.cancel')}
          </button>
          <button className="green Button" type="button" onClick={() => handleUpdateUser()}>
            {t('webapp.action.save')}
          </button>
        </div>
      </div>
      {renderPopup()}
    </div>
  )
}

FormEditUser.propTypes = {
  currentCustomer: PropTypes.shape({}).isRequired,
  setIsShowEdit: PropTypes.func.isRequired,
}

export default FormEditUser
