import React, { useEffect, useRef, useState } from 'react'
import { isEmpty, map, get } from 'lodash'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
// ASSETS
import { LUCKY_STAR, WALLET_ICON, CASH_BACK_PRICE_NOTE_ICON } from '../../constants/imageConstants'

// ACTIONS
import updateCurrentPopupID from 'store/actions/new_booking/currentPopupIDActionCreators'

// CONSTANTS
import { POPUP_CASHBACK_EXPLAIN } from '../../constants/common/popupConstants'
import {
  NOT_SAME_PERCENT
} from '../../constants/common/batchConstants'
// UTILS

import { Utils } from '../../utils/Utils'
import commonUtils from '../../utils/common'

const CASH_BACK_TYPE = 'cashBack'
const FPR_TYPE = 'priceText'
const CREDIT_TYPE = 'credit'

const matchCreditItem = (list) => list?.find(item => item.type === CREDIT_TYPE)
const matchFPRItem = (list) => list?.find(item => item.type === FPR_TYPE)
const fprItem = (priceText) => ({
  icon: CASH_BACK_PRICE_NOTE_ICON,
  content: priceText,
  type: FPR_TYPE
})
const creditItem = (content) => ({
  icon: WALLET_ICON,
  content,
  type: CREDIT_TYPE,
})

const CashBackRenderItems = (props) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const credit = useSelector(state => state.currentCustomer?.credit)
  const extraInfos = useSelector(state => state.extraInfos)

  const [items, setItems] = useState([])
  const [needTransition, setNeedTransition] = useState(false)
  const isTwoLine = useRef(null)
  const isCheckLineSuccess = useRef(null)
  const isFirstLoad = useRef(null)
  const interval = useRef(null)

  const {
    cashback_dynamic_texts: cashBackDynamicText,
    country_language: countryLanguage,
    currency,
  } = extraInfos
  const { cashBackPercent, hideCashBackBar, priceText } = props
  const isEveryTitle = commonUtils.isMultiple() || commonUtils.isBatchEZ() || commonUtils.isSmartLoad()
  let textCashBackBar = ''
  if (!isEmpty(cashBackDynamicText)) {
    textCashBackBar = isEveryTitle
      ? cashBackDynamicText?.cashback_bar_multiple?.text : cashBackDynamicText?.cashback_bar_single?.text
  }
  const displayCashBackBar = (cashBackPercent && cashBackPercent !== NOT_SAME_PERCENT)
  || (isEveryTitle
    ? !isEmpty(cashBackDynamicText?.cashback_bar_multiple)
    : !isEmpty(cashBackDynamicText?.cashback_bar_single))
  const creditAmount = get(credit, 'amount', 0)
  const displayCreditAvailable = creditAmount ? Utils.formatFee(+creditAmount, countryLanguage, currency) : 0
  const creditText = t('webapp.cashback_bar.credit_available', { creditBalance: displayCreditAvailable })
  const isDisplayPopupBar = (cashBackPercent && cashBackPercent !== NOT_SAME_PERCENT)
    || (isEveryTitle
      ? (!isEmpty(cashBackDynamicText?.cashback_popup_content_multiple)
        && !isEmpty(cashBackDynamicText?.cashback_popup_title_multiple)
      )
      : (!isEmpty(cashBackDynamicText?.cashback_popup_content_single)
        && !isEmpty(cashBackDynamicText?.cashback_popup_title_single)
      ))

  const callSetInterval = () => {
    if (interval.current) {
      clearInterval(interval.current)
    }
    interval.current = setInterval(() => {
      setNeedTransition(true)
    }, 4000)
  }

  const setIsTwoLine = () => {
    const list = document.querySelectorAll('.cashback-item')
    if (!isEmpty(list) && !needTransition) {
      let isHaveTwoLine = false
      list.forEach((item) => {
        if (item && item.children[1]?.clientHeight > 20) isHaveTwoLine = true
      })
      isTwoLine.current = isHaveTwoLine
      isCheckLineSuccess.current = true
    }
  }

  const initItems = () => {
    const items = []
    if (displayCashBackBar) {
      items.push({
        icon: LUCKY_STAR,
        content: (cashBackPercent && cashBackPercent !== NOT_SAME_PERCENT)
          ? t(`webapp.cashback_bar.${isEveryTitle ? 'percent_cashback_every' : 'percent_cashback'}`, { percent: cashBackPercent })
          : textCashBackBar,
        type: CASH_BACK_TYPE
      })
    }

    if (displayCreditAvailable) {
      items.push(creditItem(creditText))
    }

    if (priceText) {
      items.push(fprItem(priceText))
    }
    setItems(items)
  }

  useEffect(() => {
    callSetInterval()
    initItems()
    setIsTwoLine()

    return () => {
      if (interval.current) {
        clearInterval(interval.current)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    callSetInterval()
    handleSliderTranslateEnd()
  }, [hideCashBackBar])

  useEffect(() => {
    if (!isEveryTitle) setIsTwoLine()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEveryTitle, items, needTransition])

  useEffect(() => {
    initItems()
  }, [cashBackPercent])

  const handleAddFPRTextAndCredit = (priceText) => {
    if (!isFirstLoad.current) {
      isFirstLoad.current = true
      return
    }

    if (isEveryTitle) return items
    let newItems = [...items]

    if (!isEmpty(newItems) && priceText) {
      const mathItem = items.findIndex(item => item.type === FPR_TYPE)
      if (mathItem === -1) {
        if (newItems[0].type === CASH_BACK_TYPE) {
          newItems.push(fprItem(priceText))
        } else {
          newItems[1] = fprItem(priceText)
          newItems[2] = { ...items[1] }
        }
      }
    }
    if (!priceText) newItems = newItems.filter(item => item.type !== FPR_TYPE)

    if (newItems.length < 2 && displayCreditAvailable && isEmpty(matchCreditItem(newItems))) {
      newItems.push(creditItem(creditText))
    }
    setItems(newItems)
  }

  useEffect(() => {
    handleAddFPRTextAndCredit(priceText)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [priceText, displayCreditAvailable])

  const sliderStyle = () => {
    if (needTransition) {
      if (isTwoLine.current) return 'slide-up-two-line'
      return 'slide-up'
    }
    return ''
  }

  const handleSliderTranslateEnd = () => {
    const lastItem = items[items.length - 1]
    let newItems = []
    if (isEmpty(matchCreditItem(items)) && displayCreditAvailable && lastItem?.type === CASH_BACK_TYPE) {
      newItems.push(lastItem)
      newItems.push(creditItem(creditText))
      newItems.push(items[0])
    } else {
      newItems = [...items.slice(1, items.length), ...items.slice(0, 1)]
    }
    setItems(newItems)
    setNeedTransition(false)
  }

  const handleShowPopupExplain = () => {
    const body = document.getElementsByTagName('BODY')[0]
    if (body) {
      body.scrollTop = 0
      body.style['overflow'] = 'hidden'
    }
    dispatch(updateCurrentPopupID(POPUP_CASHBACK_EXPLAIN))
  }

  const handleShowPopup = () => {
    if (isDisplayPopupBar && items[0].type !== FPR_TYPE) {
      handleShowPopupExplain()
    }
  }

  if (isEmpty(items)) return null

  const isShowCashBack = !hideCashBackBar
    && (displayCashBackBar || !isEmpty(matchCreditItem(items)) || !isEmpty(matchFPRItem(items)))
    && isCheckLineSuccess.current
  const isShowFPR = !isEmpty(matchFPRItem(items)) && isCheckLineSuccess.current

  if (isShowFPR && hideCashBackBar) {
    return (
      <div className="fpr-text">
        <img src={CASH_BACK_PRICE_NOTE_ICON} alt="fpr-icon" />
        <p className="line-clamp-2">
          {priceText}
        </p>
      </div>
    )
  }

  const innerClassName = items.length > 1 && (isShowCashBack || isShowFPR) ? sliderStyle() : ''

  return (
    <div className={`cashback-bar items-wrapper ${isTwoLine.current ? 'items-wrapper__two-line' : ''} ${isShowCashBack || isShowFPR ? '' : 'hide-cashback-bar'}`}>
      <div
        className={innerClassName}
        onTransitionEnd={handleSliderTranslateEnd}
        onClick={() => handleShowPopup()}
      >
        {
          map(items, item => (
            <div
              className={`cashback-item ${item.type !== FPR_TYPE ? 'cur-pointer' : ''}`}
              key={item.type}
            >
              <img src={item.icon} alt="cashback-icon" />
              <p className="cashback-text">
                {item.content}
              </p>
            </div>
          ))
        }
      </div>
    </div>
  )
}

CashBackRenderItems.propTypes = {
  extraInfos: PropTypes.shape({
    cashback_dynamic_texts: PropTypes.shape({
      cashback_popup_content_multiple: PropTypes.string,
      cashback_popup_title_multiple: PropTypes.string,
      cashback_popup_content_single: PropTypes.string,
      cashback_popup_title_single: PropTypes.string,
      cashback_bar_multiple: PropTypes.shape({
        text: PropTypes.string,
      }),
      cashback_bar_single: PropTypes.shape({
        text: PropTypes.string,
      }),
    }),
    country_language: PropTypes.string,
    currency: PropTypes.string,
  }).isRequired,
  priceText: PropTypes.string.isRequired,
  currentCustomer: PropTypes.shape({
    credit: PropTypes.shape({}),
  }).isRequired,
  bookAgainDetails: PropTypes.shape({
    time_type: PropTypes.string,
  }).isRequired,
  booking: PropTypes.shape({
    time_type: PropTypes.string,
  }).isRequired,
  currentPopupIDActions: PropTypes.shape({}).isRequired,
  cashBackPercent: PropTypes.string.isRequired,
  hideCashBackBar: PropTypes.bool,
}

CashBackRenderItems.defaultProps = {
  hideCashBackBar: false,
}

export default CashBackRenderItems
