import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'
import Datetime from 'react-datetime'
import _, { isEmpty, get } from 'lodash-es'
import { ICON_LTL_SCHEDULE_LOGO } from 'constants/imageConstants'
import DropdownHandlingUnit from '../../common/DropdownHandlingUnit'
import Locations from '../locations/Locations'
import PickupTime from '../pickup_time/PickupTime'
import ServiceType from '../service_type/ServiceType'
import VehicleType from '../vehicle_type/VehicleType'
import { Utils } from 'utils/Utils'
import I18n from 'i18n/i18n'
import { isEditBooking } from '../../../utils/booking/common'
import CommonUtils from 'utils/common'
class StepOneContent extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      data: [],
      isShowTooltips: false,
      isShowTooltipsCalendar: false,
    }
    this.handleChangeUnit = this.handleChangeUnit.bind(this)
    this.updateData = this.updateData.bind(this)
    this.renderHoverPopup = this.renderHoverPopup.bind(this)
    this.validDateRange = this.validDateRange.bind(this)
  }

  componentDidUpdate(prevProps) {
    const { booking, handlingUnitValue } = this.props
    const { booking: prevBooking } = prevProps
    const isChangeHandlingUnitData =
      JSON.stringify(prevBooking?.handlingUnitData) !== JSON.stringify(booking?.handlingUnitData)
    if (isChangeHandlingUnitData && !isEmpty(booking?.handlingUnitData)) {
      const newData = booking.handlingUnitData.map((item) => ({
        ...item,
        onClick: () => {
          this.handleChangeUnit(item.key)
        },
        isShow: true,
        title: item.name,
        icon: item.iconUrl,
      }))
      this.updateData(newData)
    }
    if (booking.shipmentLTLDetail && !handlingUnitValue) {
      this.handleChangeUnit(booking.shipmentLTLDetail.items[0].handlingUnitId)
    }
  }

  onChangePickUpTimeLTL(selectedDate) {
    const { handleLTLPickUpTime, initDatePickup } = this.props
    const date = moment(selectedDate)
    const isSameDay = date.isSame(new Date(), 'day')
    if (isSameDay) handleLTLPickUpTime(initDatePickup)
    else handleLTLPickUpTime(date)
  }

  getUrlByCountryCode() {
    const { extraInfos } = this.props
    const countryCode = get(extraInfos, 'country_code')
    const currentLang = I18n.language.toLowerCase()
    switch (countryCode.toLowerCase()) {
      case 'id':
        if (currentLang === 'en') {
          return 'https://www.deliveree.com/id/en/partial-load-faq/'
        }
        return 'https://www.deliveree.com/id/muat-sebagian-faq/'
      case 'th':
        if (currentLang === 'en') {
          return 'https://www.deliveree.com/th/en/partial-load-service/'
        }
        return 'https://www.deliveree.com/th/partial-load-service/'
      default:
        return 'https://www.transportify.com.ph/partial-load-faq/'
    }
  }

  /**
   * Handle event click for each handling unit
   * @param {string} key of handling unit
   */
  handleChangeUnit(key) {
    const { data } = this.state
    const { handleHandlingUnitValue } = this.props
    const newItem = data.find((item) => item.key === key)
    handleHandlingUnitValue(newItem)
  }

  /**
   * validate range date valid
   * @param {moment} currentDate value of each date on calendar
   */
  // eslint-disable-next-line class-methods-use-this
  validDateRange(currentDate) {
    const { extraInfos } = this.props
    const ltlMaximumPickupTimeDays = (extraInfos.ltl_maximum_pickup_time_days || 14) + 1
    const isValidDay = Utils.checkInWorkingTimeRange({ checkTime: moment(), extraInfos, isInTimeRange: false })
    const minDate =
      isValidDay || !extraInfos.enable_same_day_ptl_booking ? moment().startOf('day') : moment().subtract(1, 'days')
    const maxDate = moment(minDate).add(ltlMaximumPickupTimeDays, 'days')
    return currentDate.isBetween(minDate, maxDate)
  }

  /**
   * update data to avoid es-lint
   * @param {Array} newData list item after formated
   */
  updateData(newData) {
    const { data } = this.state
    if (newData.length !== data.length) {
      this.setState({ data: newData })
    }
  }

  /**
   * render Popup for Calendar Hover
   */
  // eslint-disable-next-line class-methods-use-this
  renderHoverPopup() {
    const { extraInfos } = this.props
    const isIdCountry = extraInfos.country_code?.toLowerCase() === 'id'
    const time = I18n.t(`webapp.new_booking.step_1.${extraInfos.enable_same_day_ptl_booking ? 'now' : 'tomorrow'}`)
    return (
      <div className="Info PickupTime-DatePicker-Introduce">
        <span className="BoxCustom-Close" onClick={() => this.setState({ isShowTooltipsCalendar: false })}>
          <i className="b material-icons Icon">close</i>
        </span>
        <div className="Popover-Arrow" />
        <div className="PickupTime-Image Title">
          <img src={ICON_LTL_SCHEDULE_LOGO} alt="icon" />
          <b>{I18n.t('webapp.new_booking.step_1.title_partial_explain_popup')}</b>
        </div>
        <div className="PickupTime-Explanation">
          {!isIdCountry && I18n.t('webapp.new_booking.step_1.schedule_a_pickup', { time })}
          {isIdCountry && I18n.t('webapp.new_booking.step_1.schedule_a_pickup_indo', { time })}
        </div>
        <div className="pickupTime-explanation-link">
          <a href={this.getUrlByCountryCode()} target="blank">
            {I18n.t('webapp.new_booking.step_1.title_partial_explain_learn_more')}
          </a>
        </div>
      </div>
    )
  }

  /**
   * render Popup of Whole Vehicle and Partial Load
   */
  // eslint-disable-next-line class-methods-use-this
  renderHoverPopupChooseType() {
    const { isShowTooltips } = this.state
    return (
      <div className={`Popover Info Explain-Popup-choose-type ${isShowTooltips && 'visible'}`}>
        <span className="BoxCustom-Close" onClick={() => this.setState({ isShowTooltips: false })}>
          <i className="b material-icons Icon">close</i>
        </span>
        <div className="Popover-Arrow" />
        <div className="Choose-type-title Title">{I18n.t('webapp.new_booking.step_1.title_vehicle')}</div>
        <div className="Choose-type-content">{I18n.t('webapp.new_booking.step_1.content_vehicle')}</div>
      </div>
    )
  }

  /**
   * Render Tab View on Header
   */
  renderTabView() {
    const { handleViewStepOne, fullLoadView, booking, extraInfos } = this.props
    const urlParams = new URLSearchParams(window.location.search)
    const isBookAgain = urlParams.get('book_again')
    const isDisabledFTL = get(booking, 'shipmentLTLDetail.shipmentDetail.paymentMethod', 0) !== 0 && !isBookAgain
    const isDisabledPTL = fullLoadView && (booking?.created_at || isEditBooking())
    let hideTimeoutID = null
    const setDisplayTooltip = () => {
      if (hideTimeoutID) {
        clearTimeout(hideTimeoutID)
      }
      this.setState({ isShowTooltips: true })
    }

    const handleMouseleave = () => {
      clearTimeout(hideTimeoutID)
      hideTimeoutID = setTimeout(() => {
        this.setState({ isShowTooltips: false })
      }, 500)
    }

    return (
      <div
        className="Vehicles Tab-List Vehicles-List flex-wrap block"
        onMouseEnter={setDisplayTooltip}
        onMouseLeave={handleMouseleave}
      >
        <button
          type="button"
          className={`block-item m-w160 ${fullLoadView && 'selected'}`}
          onClick={() => !fullLoadView && handleViewStepOne(true)}
          disabled={isDisabledFTL}
        >
          <label>{I18n.t('webapp.new_booking.step_1.whole_vehicle')}</label>
        </button>
        {extraInfos.ltl_enable_partial_load && (
          <button
            type="button"
            className={`block-item m-w160 ${!fullLoadView && 'selected'}`}
            onClick={() => fullLoadView && handleViewStepOne(false)}
            disabled={isDisabledPTL}
          >
            <label>{I18n.t('webapp.new_booking.step_1.partial_load')}</label>
          </button>
        )}
        {extraInfos.enable_send_cargo_tab && (
          <button
            type="button"
            className={`block-item m-w160`}
            onClick={() => 
              {
                CommonUtils.moengageTrackEvent('Select Send Cargo Tab On Step 1')
                window.location = extraInfos.maritime_ph_jotform_link
              }
            }
            disabled={isDisabledPTL}
          >
            <label>{I18n.t('webapp.new_booking.step_1.send_cargo')}</label>
          </button>
        )}
        { extraInfos.ltl_enable_partial_load && this.renderHoverPopupChooseType()}
      </div>
    )
  }

  renderCalendar() {
    const { pickupTimeLTL, extraInfos, countryCode } = this.props
    const { isShowTooltipsCalendar } = this.state
    let hideTimeoutID = null
    const setDisplayTooltip = () => {
      if (hideTimeoutID) {
        clearTimeout(hideTimeoutID)
      }
      this.setState({ isShowTooltipsCalendar: true })
    }

    const handleMouseleave = () => {
      clearTimeout(hideTimeoutID)
      hideTimeoutID = setTimeout(() => {
        this.setState({ isShowTooltipsCalendar: false })
      }, 500)
    }

    return (
      <div className="Date-Picker" onMouseEnter={setDisplayTooltip} onMouseLeave={handleMouseleave}>
        <Datetime
          input={false}
          open
          disableOnClickOutside
          viewMode="days"
          isValidDate={this.validDateRange}
          onChange={(e) => this.onChangePickUpTimeLTL(e)}
          renderDay={this.renderDay}
          value={pickupTimeLTL || moment()}
        />
        <div className="notify-calendar">
          <span>{Utils.renderCalendarNotify({ pickupTimeLTL, extraInfos, countryCode })}</span>
        </div>
        <div className={`Explain-Popup Popover Info ${isShowTooltipsCalendar && 'visible'}`}>
          {this.renderHoverPopup()}
        </div>
      </div>
    )
  }

  render() {
    const { data } = this.state
    const {
      fullLoadView,
      extraInfos,
      handlingUnitValue,
      setIconComingSoonService,
      isShopping,
      defaultValue,
      myShopping,
      area,
      currentStep,
      isEditWebMultipleBooking,
      outsideList,
      setOutsideList,
      errorDistanceList,
      setListErrorDistance,
      isHaveLHAddress,
      isShowChangeTimeTypeClick,
      setIsShowChangeTimeTypeClick,
      isShowPopupChangePickupLocation,
      handleErrorPickupLocation,
      setShowComingSoonService,
      handleShowChangePickupLocation,
      extendedList,
      setComingSoonServiceTypeName,
      setIsShowPickupTimePopup,
      isEnableGoogleMap
    } = this.props
    return (
      <div className="Step-One-Content">
        {(extraInfos.ltl_enable_partial_load || extraInfos.enable_send_cargo_tab) && this.renderTabView()}
        {fullLoadView ? (
          <div>
            <ServiceType
              isShopping={isShopping}
              defaultValue={defaultValue}
              myShopping={myShopping}
              setComingSoonServiceTypeName={setComingSoonServiceTypeName}
              setShowComingSoonService={setShowComingSoonService}
              setIconComingSoonService={setIconComingSoonService}
              setIsShowPickupTimePopup={setIsShowPickupTimePopup}
            />
            <VehicleType setIsShowPickupTimePopup={setIsShowPickupTimePopup} />
            <PickupTime
              isShopping={isShopping}
              isEditWebMultipleBooking={isEditWebMultipleBooking}
              setIsShowChangeTimeTypeClick={setIsShowChangeTimeTypeClick}
              isShowChangeTimeTypeClick={isShowChangeTimeTypeClick}
              extendedList={extendedList}
            />
          </div>
        ) : (
          <div className="mt20 mar20 ml10 mr10 Partial-Load">
            <div>
              <DropdownHandlingUnit
                data={data}
                handlingUnitValue={isEmpty(handlingUnitValue) ? data[0] : handlingUnitValue}
              />
            </div>
            {this.renderCalendar()}
          </div>
        )}

        <Locations
          isShopping={isShopping}
          area={area}
          currentStep={currentStep}
          fullLoadView={fullLoadView}
          outsideList={outsideList}
          setOutsideList={setOutsideList}
          errorDistanceList={errorDistanceList}
          setListErrorDistance={setListErrorDistance}
          isHaveLHAddress={isHaveLHAddress}
          isShowPopupChangePickupLocation={isShowPopupChangePickupLocation}
          handleErrorPickupLocation={handleErrorPickupLocation}
          handleShowChangePickupLocation={handleShowChangePickupLocation}
          isEnableGoogleMap={isEnableGoogleMap}
        />
      </div>
    )
  }
}

StepOneContent.propTypes = {
  isShopping: PropTypes.bool,
  isHaveLHAddress: PropTypes.bool.isRequired,
  isEditWebMultipleBooking: PropTypes.bool,
  currentStep: PropTypes.number,
  area: PropTypes.shape({}).isRequired,
  defaultValue: PropTypes.shape({}),
  myShopping: PropTypes.shape({}),
  booking: PropTypes.shape({}),
  fullLoadView: PropTypes.bool,
  handlingUnitValue: PropTypes.shape({}),
  pickupTimeLTL: PropTypes.shape({}),
  handleViewStepOne: PropTypes.func,
  handleHandlingUnitValue: PropTypes.func,
  handleLTLPickUpTime: PropTypes.func,
  currentCustomer: PropTypes.shape({}).isRequired,
  outsideList: PropTypes.instanceOf(Array),
  errorDistanceList: PropTypes.instanceOf(Array),
  setListErrorDistance: PropTypes.func,
  setOutsideList: PropTypes.func,
  countryCode: PropTypes.string,
  isShowChangeTimeTypeClick: PropTypes.bool.isRequired,
  setIsShowChangeTimeTypeClick: PropTypes.func.isRequired,
  setShowComingSoonService: PropTypes.func.isRequired,
  extraInfos: PropTypes.shape({
    enable_same_day_ptl_booking: PropTypes.bool,
    country_code: PropTypes.string,
    area_id: PropTypes.number,
    ltl_booking_end_time: PropTypes.string,
    ltl_booking_start_time: PropTypes.string,
  }).isRequired,
  isShowPopupChangePickupLocation: PropTypes.bool,
  handleErrorPickupLocation: PropTypes.func,
  handleShowChangePickupLocation: PropTypes.func,
  extendedList: PropTypes.instanceOf(Array),
  setComingSoonServiceTypeName: PropTypes.func,
  setIconComingSoonService: PropTypes.func,
  setIsShowPickupTimePopup: PropTypes.func.isRequired,
  initDatePickup: PropTypes.shape({}),
}

StepOneContent.defaultProps = {
  isShopping: false,
  isEditWebMultipleBooking: false,
  outsideList: [],
  errorDistanceList: [],
  currentStep: 0,
  defaultValue: {},
  myShopping: {},
  booking: {},
  fullLoadView: true,
  handlingUnitValue: {},
  pickupTimeLTL: {},
  handleViewStepOne: () => undefined,
  handleHandlingUnitValue: () => undefined,
  handleLTLPickUpTime: () => undefined,
  setOutsideList: () => undefined,
  setListErrorDistance: () => undefined,
  countryCode: '',
  isShowPopupChangePickupLocation: false,
  handleErrorPickupLocation: () => undefined,
  handleShowChangePickupLocation: () => undefined,
  extendedList: [],
  setComingSoonServiceTypeName: () => undefined,
  setIconComingSoonService: '',
  initDatePickup: {},
}

export default StepOneContent
