import customersAPI from 'api/customers'
import commonUtils from './common'
import setupTwilioChat from 'utils/setupTwilioChat'
import { getDeviceId } from 'utils/cookie'

import {
  FIREBASE_ENV,
  FIREBASE_WEB_PUSH_KEY,
  FIREBASE_CONFIG
} from 'constants/appConstants'
import firebase from 'firebase'

const firebaseInit = {
  load: async () => {
    if (firebase?.apps?.length === 0) {
      firebase.initializeApp(FIREBASE_CONFIG ? JSON.parse(FIREBASE_CONFIG): {})
      const token = localStorage.getItem('access_token')

      if(token) {
        const twilioChatClient = await setupTwilioChat.getChatClient();
        firebaseInit.loadFCM(token, twilioChatClient)
      }
    }
  },
  authenticate: (token) => {
    if (token) {
      firebase.auth().signInWithCustomToken(token).catch((error) => {
        console.log('SignIn Error: ', error)
      })
    }
  },
  listen: (refPath) => {
    const path = `${FIREBASE_ENV}/${refPath}`
    return firebase.database().ref(path)
  },
  listen_root: (refPath) => {
    const path = refPath
    return firebase.database().ref(path)
  },
  loadFCM: (authenticateToken, chatClientInstance) => {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.addEventListener('message', () => {
        // check data and then play sound
        commonUtils.playSound('../../../assets/audio/FPD_notification_sound.mp3', 2)
      })

      navigator.serviceWorker.register('/my-sw.js').then((registration) => {
        let serviceWorker
        if (registration.installing) {
          serviceWorker = registration.installing
        } else if (registration.waiting) {
          serviceWorker = registration.waiting
        } else if (registration.active) {
          serviceWorker = registration.active
        }
        if (serviceWorker) {
          firebase.messaging().useServiceWorker(registration)
          // [START request_permission]
          firebase.messaging().requestPermission()
            .then(() => {
              firebase.messaging().getToken({
                ...(FIREBASE_WEB_PUSH_KEY && { vapidKey: FIREBASE_WEB_PUSH_KEY })
              })
                .then((currentToken) => {
                  if (currentToken) {
                    console.log('After request: ', currentToken)
                    window.Moengage.add_user_attribute('Web Notifications', true)
                    customersAPI.trackDevice(
                      currentToken
                    )
                    if (chatClientInstance) {
                      console.log('Setup Chat push notification')
                      chatClientInstance.setPushRegistrationId('fcm', currentToken)
                      firebase.messaging().onMessage((payload) => {
                        console.log('FirebaseSupport.registerForPushCallback', 'push received', payload)
                        if (chatClientInstance) {
                          chatClientInstance.handlePushNotification(payload)
                        }
                      })
                    }
                  }
                })
                .catch((err) => {
                  console.log('An error occurred while retrieving token. ', err)
                  window.Moengage.add_user_attribute('Web Notifications', false)
                })
            })
            .catch((err) => {
              console.log('Unable to get permission to notify.', err)
              window.Moengage.add_user_attribute('Web Notifications', false)
            })
          // [END request_permission]
          commonUtils.createTmpIframe('/manifest.json')
        }
      }).catch((err) => {
        // Something went wrong during registration. The service-worker.js file
        // might be unavailable or contain a syntax error.
        console.log('Unable to get permission to notify.', err)
        window.Moengage.add_user_attribute('Web Notifications', false)
      })
    } else {
      // The current browser doesn't support service workers.
      console.log("The current browser doesn't support service workers.")
      window.Moengage.add_user_attribute('Web Notifications', false)
    }
  }
}

export default firebaseInit
