import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import useElBoundingRect from 'hooks/useElBoundingRect'
import { Utils } from 'utils/Utils'
import './styles.scss'
import { SAVER_BLOCK_INFO_ICON } from 'constants/imageConstants'
import Popover from 'components/common/Popover'
import { isEmpty } from 'lodash'
import CommonUtils from 'utils/common'

const SaverPopup = (props) => {
  const { popupContent } = props
  const [showPopup, setShowPopup] = useState(false)
  const [ref, rect, rerender] = useElBoundingRect()
  const handleShowHideFunc = useRef()
  const titleItem = popupContent.popup_items.find(item => item.item_type === 'st_sbe_title')

  const isReduceScreen = Utils.isReduceScreen()
  const screenHeight = isReduceScreen ? window.innerHeight / 0.8 : window.innerHeight

  const positionRight = +rect?.right + 34
  const positionLeft = 0
  const topContainerScroll = 100

  useEffect(() => {
    rerender()
    if (showPopup) {
      CommonUtils.moengageTrackEvent('Select Service Explanation Popup On Step 1')
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showPopup])

  const clickOutside = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      setShowPopup(false)
    }
  }

  const handleShowHidePopup = (isShow) => {
    if (handleShowHideFunc.current) clearTimeout(handleShowHideFunc.current)
    handleShowHideFunc.current = setTimeout(() => {
      setShowPopup(isShow)
    }, 500)
  }

  const handleCloseBtnClick = () => {
    if (handleShowHideFunc.current) {
      clearTimeout(handleShowHideFunc.current)
      handleShowHideFunc.current = null
    }
    setShowPopup(false)
  }

  const handleLinkClick = async (url) => {
    await CommonUtils.moengageTrackEvent('Select On URL On Explanation Popup On Step 1')
    window.open(url)
  }

  const setClassByType = (type, index) => {
    const paddingTopList = ['st_sbe_subtitle', 'st_sbe_link']
    const paddingStyle = paddingTopList.includes(type) && index !== 0 ? 'pt10' : ''
    switch (type) {
      case 'st_sbe_title':
        return `saver-title ${paddingStyle}`
      case 'st_sbe_subtitle':
        return `saver-subtitle ${paddingStyle}`
      case 'st_sbe_link':
        return `saver-link ${paddingStyle}` 
      default:
        return `saver-standard ${paddingStyle}`
    }
  }

  const renderContentPopup = () => (
    <div className="saver-booking-content-container">
      <span id="close-popup-btn" className="BoxCustom-Close" onClick={handleCloseBtnClick}>
        <i className="b material-icons Icon">
          close
        </i>
      </span>
      {popupContent.popup_items.map((item, index) => (
        item.item_type === 'st_sbe_link' ? (
          <div onClick={() => handleLinkClick(item.link_url)} className={setClassByType(item.item_type, index)}>
            {item.content}
          </div>
        ) : (
        <div className={setClassByType(item.item_type, index)}>
          {item.content}
        </div>
        )
      ))}
    </div>
  )

  const rectPlainObject = Object.fromEntries(Array.from(Object.keys(DOMRectReadOnly.prototype).filter(k => !isNaN(rect[k])), k => [k, rect[k]]));
  const newRect = !isEmpty(rectPlainObject) ? { ...rectPlainObject, top: rectPlainObject.top - 10 } : {}
  return (
    <div
      ref={ref}
      className="saver-booking-compare-container"
      onMouseEnter={() => handleShowHidePopup(true)}
      onMouseLeave={() => handleShowHidePopup(false)}
    >
      <div className="saver-i-icon-border">
        <img className="saver-i-icon" src={SAVER_BLOCK_INFO_ICON} alt='saver-booking-block-icon' />
      </div>
      <div className="saver-booking-compare-text">{titleItem?.content || ''}</div>
      <Popover
        id="popover-saver-booking"
        showPopover={showPopup}
        anchorElRect={newRect}
        rectChildren={newRect}
        positionRight={positionRight}
        positionLeft={positionLeft}
        clickOutside={clickOutside}
        screenHeight={screenHeight}
        topContainerScroll={topContainerScroll}
      >
        {renderContentPopup()}
      </Popover>
    </div>
  )
}

SaverPopup.propTypes = {
  popupContent: PropTypes.shape({
    popup_items: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
}

export default SaverPopup
