import { API_GATEWAY } from 'constants/appConstants'
import apiClient from 'services/axiosApp'
import { getAppsFlyerUID } from 'utils/cookie'

const SettingAPI = {
  getCustomerSettingsApi: async (authenticationToken, query, callback, countryCode) => {
    try {
      const res = await apiClient.get('/api/v3/settings/customers', {
        params: query,
        headers: {
          'Country-Code': countryCode,
          'Appsflyer-Uid': getAppsFlyerUID(),
        },
      })
      return callback(res)
    } catch (err) {
      throw new Error(err)
    }
  },
  updateAutomaticSettings: async (authenticationToken, params, callback) => {
    try {
      const res = await apiClient.put('api/v3/settings/automatic_booking_frequency', params, {
        headers: {
          'Device-Type': 'Web',
        },
      })
      return callback(res)
    } catch (err) {
      throw new Error(err)
    }
  },
  getInitDataTrackingApi: async (params) => apiClient.get('/api/v3/settings/live_tracking', { params }),
  getFrontPageLink: (countryCode) =>
    apiClient.get(
      `${API_GATEWAY}/v1/setting/system-settings?keys=first_box_url&keys=second_box_url&keys=third_box_url`,
      {
        headers: {
          countrycode: countryCode,
        },
      }
    ),
  getSettingsCustomerByArea: (areaId, type = 'pickup_change') =>
    apiClient.get(`/api/v3/settings/customers_by_area?area_id=${areaId}&section_type=${type}`,
      {
        headers: {
          'Appsflyer-Uid': getAppsFlyerUID(),
        }
      }
    ),
  getFootNotesSummary: (params) => apiClient.get('/api/v3/ftl_preparation/summary_step', { params }),
  getFootNotesBooking: (bookingId) => apiClient.get(`/api/v3/ftl_preparation/bookings/${bookingId}`),
  getSettingBusiness: (params) => apiClient.get(`/api/v3/business`, { params }),
  updateSettingBusiness: (data, params) => apiClient.put(`/api/v3/business`, data, { params }),
}

export default SettingAPI
