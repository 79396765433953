import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
import StorageKeys from 'constants/storage-keys'
import paths from 'routers/paths'
import I18n from 'i18n/i18n'
import { cloneDeep, isString, isUndefined } from 'lodash'
// import queryString from 'query-string'

const serializeQuery: any = (params: any, prefix: any) => {
  const query = Object.keys(params).map((key) => {
    const value  = params[key];

    if (params.constructor === Array)
      key = `${prefix}[]`;
    else if (params.constructor === Object)
      key = (prefix ? `${prefix}[${key}]` : key);

    if(value === null || value === undefined)
      return `${key}=`;
    else if (typeof value === 'object')
      return serializeQuery(value, key);
    else
      return `${key}=${encodeURIComponent(value)}`;
  });

  return [].concat.apply([], query).join('&');
}

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    'Device-Type': 'Webapp',
    'Accept-Language': I18n.language,
  },
  paramsSerializer: (params: any) => serializeQuery(params),
})

apiClient.interceptors.request.use((config: AxiosRequestConfig) => {
  const headers = cloneDeep(config.headers)
  let finalToken
  finalToken = localStorage.getItem(StorageKeys.ACCESS_TOKEN)
  if(headers) {
    if (!isUndefined(headers?.authorization)) {
      finalToken = headers?.authorization
      delete headers.authorization
    } else if (!isUndefined(headers?.Authorization)) {
      finalToken = headers?.Authorization
      delete headers.Authorization
    }
  }

  return {
    ...config,
    headers: {
      ...headers,
      ...(finalToken ? { authorization: finalToken } : {}),
    },
  }
})

apiClient.interceptors.response.use(
  (response: AxiosResponse) => {
    // const isTypeBlob = response.config.responseType === 'blob'
    // if (!isTypeBlob && response && response.data && typeof response.data === "object") {
    //   return { ...response.data, statusApi: response.status }
    // }
    // return { ...response, statusApi: response.status }
    return response
  },
  (error: AxiosError<any>) => {
    // if (error.response) {
    //   if (error.response.status === 401) {
    //     /*
    //      * Token expired
    //      * In case fetch booking - status will be 401 :p
    //      * So I don't know which case token is expired or booking unauthorized
    //      * We need discuss to find another solution
    //      */

    //     // Add current token to blacklist to prevent reject this token if re-sync from storage hub
    //     // const currentToken = localStorage.getItem(StorageKeys.ACCESS_TOKEN);
    //     // const tokenBlacklist = JSON.parse(localStorage.getItem(StorageKeys.TOKEN_BLACKLIST)) || [];
    //     // tokenBlacklist.push(currentToken);
    //     // localStorage.setItem(StorageKeys.TOKEN_BLACKLIST, JSON.stringify(tokenBlacklist));

    //     localStorage.removeItem(StorageKeys.ACCESS_TOKEN);
    //     // localStorage.removeItem(StorageKeys.CUSTOMER_REDUCER);

    //     return (window.location.href = paths.NEW_BOOKING);
    //   }
    // }

    return error?.response || error
  }
)

export default apiClient
