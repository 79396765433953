import React from 'react'
import ReactDOM from 'react-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import $ from 'jquery'
import _ from 'lodash'

// UTILS
// ACTIONS
import * as eventNotificationActionCreators from 'store/actions/new_booking/eventNotificationActionCreators'
import { getParamFromURL } from 'utils/booking/common'
// ASSETS

const mapStateToProps = state => ({
  currentCustomer: state.currentCustomer,
  extraInfos: state.extraInfos,
})

function mapDispatchToProps(dispatch) {
  return {
    eventNotificationActions: bindActionCreators(eventNotificationActionCreators, dispatch),
  }
}

class EventNotificationPopup extends React.Component {
  constructor(props) {
    super(props)
    this.state = { noEvent: false, clickToClose: true }
  }

  componentWillMount() {
    const {
      area,
      currentCustomer,
      eventNotificationActions,
      actionWhenCloseEventNotificationPopup
    } = this.props
    eventNotificationActions.getCurrentEvent(area.id, currentCustomer, (result) => {
      if (!_.isEmpty(result?.object) && result?.has_event) {
        const { object } = result
        this.setState({
          banner_url: object.banner_url,
          weblink: object.weblink,
        })
        if (object.event_type === 'normal') {
          if (object.is_repetitive !== true && _.parseInt(localStorage.showedEventID) === object.id) {
            this.setState({ noEvent: true }, () => {
              actionWhenCloseEventNotificationPopup(true)
            })
          }

          const detectSessionLockScreen = sessionStorage.getItem('lockDisplay')

          this.setState({
            class_name: !detectSessionLockScreen ? 'Overlay visible Popup-Event' : '',
            noEvent: !!detectSessionLockScreen,
          })
          if (!sessionStorage.getItem('lockDisplay')) {
            sessionStorage.setItem('lockDisplay', 'true')
          }
          localStorage.showedEventID = object.id
        } else if ((currentCustomer.current_company_id === 0
          && $.inArray(currentCustomer.id, object.customer_ids) === -1)
          || (currentCustomer.current_company_id
            && $.inArray(currentCustomer.current_company_id, object.company_ids) === -1)) {
          this.setState({
            class_name: 'Overlay visible Popup-Event Popup-Event-Block',
            clickToClose: false
          })
        } else {
          this.setState({ noEvent: true }, () => {
            actionWhenCloseEventNotificationPopup(true)
          })
        }
      } else {
        this.setState({ noEvent: true }, () => {
          actionWhenCloseEventNotificationPopup(true)
        })
      }
    })
    return true
  }

  closePopup() {
    let eventBox = ReactDOM.findDOMNode(this.refs.eventBox)
    const { actionWhenCloseEventNotificationPopup } = this.props
    const { clickToClose } = this.state
    if (clickToClose) {
      $(eventBox).removeClass('visible')
      actionWhenCloseEventNotificationPopup(true)
    }
  }

  render() {
    const { noEvent } = this.state
    const { extraInfos } = this.props
    const isMKT = getParamFromURL('is_mkt')
    const isPTL = getParamFromURL('ptl')
    const isHideFromWebsite = isMKT || isPTL
    if (noEvent || (extraInfos.marketing_flow === true) || isHideFromWebsite) {
      return <div />
    }
    return (
      <div ref="eventBox" className={this.state.class_name}>
        <div className="Overlay-Lightbox-Image">
          {this.state.weblink ?
            <a href={this.state.weblink} target="_blank" rel="noreferrer">
              <img src={this.state.banner_url} className="Custom" />
            </a>
            : <img src={this.state.banner_url} className="Custom" />
          }
        </div>
        <span className="close-modal-common" onClick={this.closePopup.bind(this)}></span>
      </div>
    )
  }

};

EventNotificationPopup.propTypes = {
  area: PropTypes.shape({}).isRequired,
  currentCustomer: PropTypes.shape({}).isRequired,
  eventNotificationActions: PropTypes.shape({}).isRequired,
  actionWhenCloseEventNotificationPopup: PropTypes.func,
  extraInfos: PropTypes.shape({}).isRequired
}

EventNotificationPopup.defaultProps = {
  actionWhenCloseEventNotificationPopup: () => undefined,
}

export default connect(mapStateToProps, mapDispatchToProps)(EventNotificationPopup);