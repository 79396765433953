/* eslint-disable import/prefer-default-export */
export const CHANGE_CURRENT_STEP = 'CHANGE_CURRENT_STEP'
export const CHANGE_TIME_TYPE = 'CHANGE_TIME_TYPE'
export const CHANGE_TIME_TYPE_UI = 'CHANGE_TIME_TYPE_UI'
export const AUTO_SWITCH_LONG_HAUL = 'AUTO_SWITCH_LONG_HAUL'
export const SHOW_DATE_PICKER = 'SHOW_DATE_PICKER'
export const CHANGE_PICKUP_TIME = 'CHANGE_PICKUP_TIME'
export const SET_SERVICE_TYPES = 'SET_SERVICE_TYPES'
export const SET_VEHICLE_TYPES = 'SET_VEHICLE_TYPES'
export const SET_SELECTED_SERVICE_TYPE_ID = 'SET_SELECTED_SERVICE_TYPE_ID'
export const SET_SELECTED_VEHICLE_TYPE_ID = 'SET_SELECTED_VEHICLE_TYPE_ID'
export const GET_ONLINE_DRIVERS_ON_VEHICLE_TYPE = 'GET_ONLINE_DRIVERS_ON_VEHICLE_TYPE'
export const SET_EXTRA_INFOS = 'SET_EXTRA_INFOS'
export const CHANGE_QUICK_CHOICE = 'CHANGE_QUICK_CHOICE'
export const SHOW_QUICK_CHOICE = 'SHOW_QUICK_CHOICE'
export const SHOULD_GO_TO_STEP_2 = 'SHOULD_GO_TO_STEP_2'
// FOR LOCATIONS
export const ADD_LOCATION = 'ADD_LOCATION'
export const INSERT_LOCATION = 'INSERT_LOCATION'
export const REMOVE_LOCATION = 'REMOVE_LOCATION'
export const UPDATE_LOCATION = 'UPDATE_LOCATION'
export const UPDATE_LAZYADDRESS_LOCATION_NEW_BOOKING = 'UPDATE_LAZYADDRESS_LOCATION_NEW_BOOKING'
export const UPDATE_LOCATION_ID = 'UPDATE_LOCATION_ID'
export const ORDER_LOCATION = 'ORDER_LOCATION'
export const OPTIMIZE_LOCATION = 'OPTIMIZE_LOCATION'
export const SET_LOCATIONS = 'SET_LOCATIONS'
export const SET_LOCATIONS_WITHOUT_ATTRS = 'SET_LOCATIONS_WITHOUT_ATTRS'
export const BACKUP_LOCATIONS = 'BACKUP_LOCATIONS'
export const UPDATE_OPTIMIZE_STATE = 'UPDATE_OPTIMIZE_STATE'
export const UPDATE_TMP_LOCATION = 'UPDATE_TMP_LOCATION'
export const SET_TMP_LOCATIONS = 'SET_TMP_LOCATIONS'
export const CHECK_LOCATION_INFOS = 'CHECK_LOCATION_INFOS'
export const RESET_LOCATION = 'RESET_LOCATION'
// FOR MAP
export const LOAD_MAP = 'LOAD_MAP'
export const RENDER_DRIVER_MARKERS = 'RENDER_DRIVER_MARKERS'
export const ADD_DRIVER = 'ADD_DRIVER'
export const REMOVE_DRIVER = 'REMOVE_DRIVER'
export const UPDATE_DRIVER = 'UPDATE_DRIVER'
export const SET_GEO_QUERY = 'SET_GEO_QUERY'
export const ADD_GOOGLE_TYPE = 'ADD_GOOGLE_TYPE'
export const UPDATE_GOOGLE_TYPE = 'UPDATE_GOOGLE_TYPE'
export const REMOVE_GOOGLE_TYPE = 'REMOVE_GOOGLE_TYPE'
export const REMOVE_BLUR_MARKER = 'REMOVE_BLUR_MARKER'
export const SET_MAP_SERVICE = 'SET_MAP_SERVICE'
// FOR BOOK AGAIN
export const UPDATE_BOOK_AGAIN_DETAILS = 'UPDATE_BOOK_AGAIN_DETAILS'
export const UPDATE_BOOK_AGAIN_SETTLEMENT_DETAILS = 'UPDATE_BOOK_AGAIN_SETTLEMENT_DETAILS'
// FOR EXTRA SERVICES
export const UPDATE_ATTACHMENT = 'UPDATE_ATTACHMENT'
export const UPDATE_ATTACHMENTS = 'UPDATE_ATTACHMENTS'
export const UPDATE_DISCOUNT_CODE = 'UPDATE_DISCOUNT_CODE'
export const UPDATE_DISCOUNT_CODE_STATUS = 'UPDATE_DISCOUNT_CODE_STATUS'
export const UPDATE_JOB_ORDER_NUMBER = 'UPDATE_JOB_ORDER_NUMBER'
export const UPDATE_NOTE = 'UPDATE_NOTE'
export const UPDATE_OTHERS = 'UPDATE_OTHERS'
// FOR OUT OF SERVICE
export const UPDATE_OUT_OF_SERVICE_STATUS = 'UPDATE_OUT_OF_SERVICE_STATUS'
// FOR CUSTOMER
export const UPDATE_CUSTOMER = 'UPDATE_CUSTOMER'
export const IS_LOGIN_STEP_3 = 'IS_LOGIN_STEP_3'
// FOR CASHBACK
export const CALCULATE_CASHBACK = 'CALCULATE_CASHBACK'
export const RESET_CASHBACK_PERCENT = 'RESET_CASHBACK_PERCENT'
// FOR GENERAL VALUES
export const MIN_NUMBER_OF_LOCATIONS = 2
export const NOW = 'now'
export const SCHEDULE = 'schedule'
export const FULL_DAY = 'full_day'
export const QUICK_CHOICE = 'quick_choice'
export const MAX_SIZE_OF_FILE = 6000000 // 6mb
export const MAX_SIZE_OF_FILE_BEFORE_UPLOAD = 100000000 // 100mb
export const ATTACHMENT_FORMAT_TYPE = ['application/pdf', 'image/jpeg', 'image/png', 'image/jpg']
export const NOW_ENABLED_SETTING = 'immediate_enabled'

// FOR POPUP IDS (DOM)
export const POPUP_ID_SERVICE_TYPE_COMING_SOON = 'service-type-coming-soon-popup'
export const POPUP_ID_FULL_DAY_S1 = 'vehicle-type-full-day-s1-popup'
export const POPUP_ID_FULL_DAY_S2 = 'vehicle-type-full-day-s2-popup'
export const POPUP_ID_OUT_OF_SERVICE = 'out-of-service-popup'
export const POPUP_ID_EXTEND_SERVICE_AREA = 'extend-service-area-popup'

export const POPUP_ID_SERVICE_TYPE_POPUP_EYE = 'service-type-popup-eye-'
export const POPUP_ID_COD_INVOICE_FEE = 'cod-invoice-fees-'
export const POPUP_ID_EXTRA_SERVICE_POPUP_EYE = 'extra-service-popup-eye-'

// FOR POPUP TYPES
export const POPUP_TYPE_SERVICE_TYPE_POPUP_EYE = 'st_pe'
export const POPUP_TYPE_EXTRA_POPUP_EYE = 'es_pe'
export const POPUP_TYPE_EXTRA_POPUP_EYE_PACKAGE = 'es_pk_pe'
export const POPUP_TYPE_EXTRA_POPUP_EYE_FULL_DAY = 'es_fd_pe'
export const POPUP_TYPE_VEHICLE_TYPE_FULL_DAY_S1 = 'vt_fd_s1'
export const POPUP_TYPE_VEHICLE_TYPE_FULL_DAY_S2 = 'vt_fd_s2'

// FOR POPUP ITEM TYPE
export const ITEM_TYPE_SERVICE_TYPE_PE_TITLE = 'st_pe_title'
export const ITEM_TYPE_SERVICE_TYPE_PE_CONTENT = 'st_pe_content'
export const ITEM_TYPE_SERVICE_TYPE_PE_SIZE_TEXT = 'st_pe_size_text'
export const ITEM_TYPE_SERVICE_TYPE_PE_WEIGHT_TEXT = 'st_pe_weight_text'
export const ITEM_TYPE_EXTRA_PE_TITLE = 'es_pe_title'
export const ITEM_TYPE_EXTRA_PE_SUBTITLE = 'es_pe_subtitle'
export const ITEM_TYPE_EXTRA_PE_CONTENT = 'es_pe_content'
export const ITEM_TYPE_EXTRA_PE_CONTENT_LAST = 'es_pe_content_last'
export const ITEM_TYPE_EXTRA_PE_CONTENT_FINAL = 'es_pe_content_final'
export const ITEM_TYPE_VEHICLE_TYPE_FD_TITLE = 'vt_fd_title'
export const ITEM_TYPE_VEHICLE_TYPE_FD_SUBTITLE = 'vt_fd_subtitle'
export const ITEM_TYPE_VEHICLE_TYPE_FD_CONTENT = 'vt_fd_content'
export const ITEM_TYPE_VEHICLE_TYPE_FD_CONTENT_LONG = 'vt_fd_content_long'
export const ITEM_TYPE_VEHICLE_TYPE_FD_CONTENT_SMALL = 'vt_fd_content_small'

// FOR ELEMENT IDS
export const ID_RECIPIENT_NAME = 'recipient-name-'
export const ID_RECIPIENT_PHONE = 'recipient-phone-'
export const ID_NEW_SERVICE_TYPE = 'newServiceTypeId'
export const ID_NEW_VEHICLE_TYPE = 'newVehicleTypeId'

// FOR CONTACTS
export const ALLOW_DRIVER_TO_CALL = 'ALLOW_DRIVER_TO_CALL'
export const CLEAR_CUSTOMER_MASK_PHONE = 'CLEAR_CUSTOMER_MASK_PHONE'
export const CHECK_CUSTOMER_MASK_PHONE = 'CHECK_CUSTOMER_MASK_PHONE'

// FOR PREFERENCE DRIVER
export const DRIVER_PREFERENCES_POPUP = 'driver_preferences_popup'
export const DRIVER_PREFERENCES = {
  all: 'all',
  favorite: 'favorite',
  assign: 'assign',
  any: 'any',
  specific: 'specific',
  checked_favorite_driver_number: 5,
  banned: 'banned',
}
export const ASSIGNED_PICKUP_TIME = 'ASSIGNED_PICKUP_TIME'
export const UPDATE_ASSIGN_DRIVER = 'UPDATE_ASSIGN_DRIVER'
export const SET_IS_NEW_DRIVER = 'SET_IS_NEW_DRIVER'
export const SET_IS_SAVE_PREVIOUS_BOOKING_ID = 'SET_IS_SAVE_PREVIOUS_BOOKING_ID'
export const CHANGE_DATE_ASSIGN_DRIVER_UNAVAILABLE_TEMP = 'CHANGE_DATE_ASSIGN_DRIVER_UNAVAILABLE_TEMP'
export const CHANGE_TIME_ASSIGN_DRIVER_UNAVAILABLE_TEMP = 'CHANGE_TIME_ASSIGN_DRIVER_UNAVAILABLE_TEMP'
export const UPDATE_ESTIMATED_WORKING_TIME = 'UPDATE_ESTIMATED_WORKING_TIME'
export const ASSIGNED_DRIVER_CONFLICT = {
  any_driver: 'ANY_DRIVER',
  favorite_driver: 'FAVORITE_DRIVER',
  specific_driver: 'SPECIFIC_DRIVER',
}
export const MAX_LENGTH_30_CHARACTER = 30
export const SHOW_3_DOTTED = '...'
export const ADD_FAVORITE_DRIVERS = 'ADD_FAVORITE_DRIVERS'
export const MAX_HEIGHT_VEHICLE_ICON = 54
export const MAX_CODE_VERIFY = 4

// Shopping List
export const SHOPPING_UPDATE_LIST = 'SHOPPING_UPDATE_LIST'
export const SHOPPING_LIST_ITEM_STATUS = {
  // for new and edit booking
  waiting: 'waiting',
  editting: 'editting',
  pending: 'pending',
  not_available: 'not_available',
  confirmed: 'confirmed'
}
export const SHOPPING_LIST_STATUS = {
  // for new and edit booking
  locked: 'locked',
  not_locked: 'not_locked',
}
export const SHOPPING_FIELDS = ['credit_balance', 'has_upcoming_shopping_booking', 'bca_virtual_account', 'dlvr_bank_information']

// For ceb text edit mode
export const UPDATE_EDIT_MODE_TEXT = 'UPDATE_EDIT_MODE_TEXT'
// For text of Color Banner
export const UPDATE_STYLE_WARNING_CALENDAR_BOOKING = 'UPDATE_STYLE_WARNING_CALENDAR_BOOKING'
export const SHOW_WARNING_CALENDAR_BOOKING = 'SHOW_WARNING_CALENDAR_BOOKING'
export const HIDE_WARNING_CALENDAR_BOOKING = 'HIDE_WARNING_CALENDAR_BOOKING'

// For Calendar Book
export const ADD_ID_CALENDAR = 'ADD_ID_CALENDAR'
export const REMOVE_ID_CALENDAR = 'REMOVE_ID_CALENDAR'
export const RESET_CALENDAR = 'RESET_CALENDAR'
export const UPDATE_CALENDAR = 'UPDATE_CALENDAR'

// For prev time type
export const CHANGE_PREV_TIME_TYPE = 'CHANGE_PREV_TIME_TYPE'

// For tooltip
export const VISIBLE_CLASS = ' visible'
export const ERROR_DISTANCE_CLASS = ' dlvr-tooltip-error-distance'
export const FULL_DAY_MEGAZONE_CLASS = ' dlvr-tooltip-error-fd-megazone'
export const COMPLETED = 'completed'
export const CANCELED = 'canceled'

// For header
export const UPDATE_AREA_ID = 'UPDATE_AREA_ID'
export const UPDATE_ACTIVE_AREA = 'UPDATE_ACTIVE_AREA'
export const FREE_LABEL_KEY = 'webapp.label.free'

// For payment
export const DISABLED_PAYMENT_METHOD_STATUS = ['paid_by_credit_and_code', 'paid_by_credit', 'paid_by_code']