export const RECENT_LOCATIONS = 'RECENT_LOCATIONS'
export const RADIUS_RECENT_LOCATION = 0.02 // unit: km
export const LAZY_SHOW_TOAST_MESSAGE = 'toast'
export const LAZY_SHOW_TOOLTIP_MESSAGE = 'tooltip'

export const X_USE_CASE = {
  SHOW_MORE: 'show_more',
  NEW_BOOKING: 'new_booking',
}

// isShow = false for case don't show no-result, but show show more and contact cs
export const NO_RESULTS = {
  id: 'no-results',
  isShow: true
}

export const SOURCE_GOOGLE = 'DG'
