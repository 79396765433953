import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import SettingAPI from 'api/settings'
import useElBoundingRect from 'hooks/useElBoundingRect'
import ListFootNotes from './ListFootNotes'
import { Utils } from 'utils/Utils'
import I18n from 'i18n/i18n'
import './styles.scss'

const FootNotes = (props) => {
  const {
    booking, isMultiple, currentCustomer, refContainerScroll, isBookingDetail
  } = props
  const [footNotes, setFootNotes] = useState([])
  const [showPopup, setShowPopup] = useState(false)
  const [ref, rect, rerender] = useElBoundingRect()

  const isReduceScreen = Utils.isReduceScreen()
  let screenHeight = isReduceScreen ? window.innerHeight / 0.8 : window.innerHeight
  const screenWidth = isReduceScreen ? window.innerWidth / 0.8 : window.innerWidth

  let positionRight = +rect?.right + 46
  let positionLeft = 0
  let topContainerScroll = 100
  let footerHeight = 56
  let navbarHeight = 76

  const positionRightPopover = positionRight + 362 + 12

  if (isMultiple) {
    screenHeight -= isReduceScreen ? 76 : 56
    footerHeight = 62
    const showPopoverLeft = positionRightPopover > screenWidth
    if (showPopoverLeft) {
      positionRight = 0
      positionLeft = screenWidth - +rect?.left + 36
    }
  }

  if (isBookingDetail) {
    footerHeight = 0
    navbarHeight = 16
    positionRight = 0
    positionLeft = screenWidth - +rect?.left + 10
  }

  const domContainerScroll = refContainerScroll?.current

  if (domContainerScroll) {
    topContainerScroll = domContainerScroll.getBoundingClientRect().top
  }

  useEffect(() => {
    rerender()
  }, [showPopup])

  // const handleRerender = () => rerender()

  // useEffect(() => {
  //   document.addEventListener('scrollend', handleRerender)
  //   return () => {
  //     document.removeEventListener('scrollend', handleRerender)
  //   }
  // }, [])

  // useEffect(() => {
  //   if (domContainerScroll) {
  //     domContainerScroll.addEventListener('scrollend', handleRerender)
  //   }
  //   return () => {
  //     if (domContainerScroll) {
  //       domContainerScroll.removeEventListener('scrollend', handleRerender)
  //     }
  //   }
  // }, [domContainerScroll])

  useEffect(() => {
    const getFootNotesSummary = async () => {
      const res = await SettingAPI.getFootNotesSummary({
        vehicle_type_id: booking.vehicle_type_id,
        time_type: booking.time_type,
        is_surcharge: booking.has_surcharge,
        ...(currentCustomer.current_company_id && { company_id: currentCustomer.current_company_id }),
      })
      if (res.status === 200) {
        setFootNotes(res.data?.object?.footnotes || [])
      }
    }

    const getFootNotesBooking = async () => {
      if(!booking.id) return
      const res = await SettingAPI.getFootNotesBooking(booking.id)
      if (res.status === 200) {
        setFootNotes(res.data?.object?.footnotes || [])
      }
    }
    if (isBookingDetail) {
      getFootNotesBooking()
    } else if (booking.has_surcharge === true || booking.has_surcharge === false) {
      getFootNotesSummary()
    }
  }, [booking.has_surcharge])

  if (!footNotes.length) return null
  return (
    <div className="foot-notes">
      <div className="foot-notes-main">
        <div className="header">
          {I18n.t('webapp.pricing_display.additional_info')}
        </div>
        <div ref={ref}>
          {footNotes.map((item, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <div className="foot-notes-row" key={index} onClick={() => setShowPopup(!showPopup)}>
              <div>
                {item.heading}
              </div>
              <i className={`material-icons Icon ${showPopup ? 'gray' : 'green'}`}>
                chevron_right
              </i>
            </div>
          ))}
        </div>
      </div>
      <ListFootNotes
        footNotes={footNotes}
        anchorElRect={rect}
        showPopup={showPopup}
        setShowPopup={setShowPopup}
        anchorRef={ref}
        screenHeight={screenHeight}
        positionRight={positionRight}
        positionLeft={positionLeft}
        topContainerScroll={topContainerScroll}
        footerHeight={footerHeight}
        navbarHeight={navbarHeight}
      />
    </div>
  )
}

FootNotes.propTypes = {
  booking: PropTypes.shape({
    id: PropTypes.string,
    vehicle_type_id: PropTypes.string,
    time_type: PropTypes.string,
    has_surcharge: PropTypes.string,
    company_id: PropTypes.string,

  }).isRequired,
  currentCustomer: PropTypes.shape({
    authentication_token: PropTypes.string,
    current_company_id: PropTypes.number,
  }).isRequired,
  refContainerScroll: PropTypes.shape({
    current: PropTypes.shape({})
  }),
  isMultiple: PropTypes.bool,
  isBookingDetail: PropTypes.bool,
}
FootNotes.defaultProps = {
  refContainerScroll: {},
  isMultiple: false,
  isBookingDetail: false
}

export default FootNotes
