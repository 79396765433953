import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import $ from 'jquery'
import _ from 'lodash'

// UTILS
import commonUtils from 'utils/common'
import I18n from 'i18n/i18n'
// ACTIONS
import * as locationActionCreators from 'store/actions/new_booking/locationActionCreators'
import * as stepActionCreators from 'store/actions/new_booking/stepActionCreators'
// COMPONENTS
// CONSTANTS
import {
  POPUP_ID_OUT_OF_SERVICE,
  POPUP_ID_EXTEND_SERVICE_AREA,
} from 'constants/common/popupConstants'
// ASSETS
import { V2_ICON_GREEN, V2_ICON_GREEN_TRANSPORTIFY, EXTEND_MAP_ICON } from 'constants/imageConstants'
import { FULL_DAY } from 'constants/bookingConstants'
import { getCurentCompanyTypeId } from 'utils/booking/common'
import { withNavigate } from 'hocs/withNavigate'

const mapStateToProps = state => ({
  currentCustomer: state.currentCustomer,
  currentStep: state.currentStep,
  outOfServiceStatus: state.outOfServiceStatus,
  timeType: state.timeType,
})


function mapDispatchToProps(dispatch) {
  return {
    locationActions: bindActionCreators(locationActionCreators, dispatch),
    stepActions: bindActionCreators(stepActionCreators, dispatch),
  }
}

class OutServiceAreaPopup extends React.Component {
  constructor(props) {
    super(props)
    this.escFunction = this.escFunction.bind(this)
    this.onS1CancelHandler = this.onS1CancelHandler.bind(this)
    this.onS1ContinueHandler = this.onS1ContinueHandler.bind(this)
  }

  componentDidMount() {
    document.addEventListener('keydown', this.escFunction, false)
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.escFunction, false)
  }

  onS1CancelHandler() {
    $(`#${POPUP_ID_OUT_OF_SERVICE}`).removeClass("visible")
    this.props.stepActions.toggleDraggableMarkers(true)
  }

  onS1ContinueHandler() {
    const {
      currentCustomer,
      locationActions,
      navigate
    } = this.props
    $(`#${POPUP_ID_OUT_OF_SERVICE}`).removeClass('visible')
    const companyTypeId = getCurentCompanyTypeId(currentCustomer)
    if (companyTypeId) {
      locationActions.updateCODPODFees()
    }
    this.props.stepActions.nextStep(navigate)
  }

  loadModifiedTitle(title) {
    return (
      <h5 className="Normal-Booking-Title">
        {title}
      </h5>
    )
  }

  contentElement(item, key) {
    let url = item.link_url
    let content = item.content
    if (!_.isUndefined(url) && url.length > 0) {
      let linkText = item.link_text
      if (!_.isUndefined(linkText) && linkText.length > 0 &&
        content.indexOf(linkText) != -1 &&
        _.trim(content).length >= _.trim(linkText).length) {
        let subContent = "<a href='" + url + "' class='modified-popup-url'>" + linkText + "</a>"
        content = content.split(linkText).join(subContent)
      }
    }
    return (
      <div key={key}>
        <p className="center pre-line" dangerouslySetInnerHTML={{ __html: content }}></p>
      </div>
    )
  }

  contentSmallElement(item, key) {
    let url = item.link_url
    let content = item.content
    if (!_.isUndefined(url) && url.length > 0) {
      let linkText = item.link_text
      if (!_.isUndefined(linkText) && linkText.length > 0 &&
        content.indexOf(linkText) != -1 &&
        _.trim(content).length >= _.trim(linkText).length) {
        const subContent = `<a class='hyperlink-default-color' href='${url}' target='_blank'>${linkText}</a>`
        content = content.split(linkText).join(subContent)
      }
    }
    return (
      <div key={key}>
        <p className="center yellow fullday-custom" dangerouslySetInnerHTML={{ __html: content }}></p>
      </div>
    )
  }

  modifiedPopup(popupContent, iconDefault) {
    let icon = popupContent[0]["icon_url"] ? popupContent[0]["icon_url"] : iconDefault
    let iconSize = 'icon-modified-style'
    var title, _this = this, itemContent = [], itemContentSmall = []
    popupContent.map((item, index) => {
      if (item.item_type == 'ar_oos_title' || item.item_type == 'vt_oos_title') {
        title = _this.loadModifiedTitle(item.content)
      } else if (item.item_type == 'ar_oos_content_small' || item.item_type == 'vt_oos_content_small') {
        itemContentSmall.push(item)
      } else if (!_.isUndefined(item.item_type) && (item.item_type.indexOf('ar_oos_content') != -1 || item.item_type.indexOf('vt_oos_content') != -1) || item.item_type == 'ar_cts_content') {
        itemContent.push(item)
      }
    })
    var contents = itemContent.map((value, key) => {
      return this.contentElement(value, key)
    })
    var contentSmalls = itemContentSmall.map((value, key) => {
      return this.contentSmallElement(value, key)
    })
    return (
      <div className="Box Modified-Popup">
        <div className="Box-Icon">
          <img src={icon} alt="icon" className={iconSize} />
        </div>
        <div className="Box-Content">
          <div className="Normal">
            {title}
            {contents}
            {contentSmalls}
          </div>
        </div>
      </div>
    )
  }

  escFunction(event) {
    if (event.keyCode === 27) {
      this.onS1CancelHandler()
    }
  }

  render() {
    if (this.props.outOfServiceStatus.error)
      return (<span />)
    if (_.isEmpty(this.props.outOfServiceStatus))
      return (<span />)
    if (this.props.outOfServiceStatus.is_inside == true && this.props.outOfServiceStatus.out_of_service_area.is_surcharged == false)
      return (<span />)
    let icon = commonUtils.isTransportify() ? V2_ICON_GREEN_TRANSPORTIFY : V2_ICON_GREEN
    let className = "visible vertical-scroll Modal modal-outOfService Modal-No-action"
    if (this.props.outOfServiceStatus.out_of_service_area.is_surcharged == true) {
      icon = EXTEND_MAP_ICON
      className = "visible vertical-scroll Modal modal-outOfService"
    }
    let loadPopup
    let popupContent = this.props.outOfServiceStatus.oos_popup_content
    const isOSA = popupContent && popupContent[1].item_type === 'ar_oos_title'
    loadPopup = this.modifiedPopup(popupContent, icon)
    return (
      <div id={POPUP_ID_OUT_OF_SERVICE} className={className}>
        <div className={`Normal-Booking ${isOSA ? 'Normal-Booking-prefix' : ''} z-index-2`}>
          {loadPopup}
          {this.props.outOfServiceStatus.out_of_service_area.is_surcharged == true &&
            (
              <div className="Normal-Booking-Actions Custom">
                <a
                  className={`Gray no-hover ${isOSA ? 'dlvr-button-prefix' : ''}`}
                  onClick={this.onS1CancelHandler}
                >
                  {I18n.t('webapp.new_booking.back')}
                </a>
                <a
                  className={`White no-hover ${isOSA ? 'dlvr-button-prefix' : ''}`}
                  onClick={this.onS1ContinueHandler}
                >
                  {I18n.t('webapp.action.proceed')}
                </a>
              </div>
            )}
        </div>
        <div className="close-modal-common" onClick={() => this.onS1CancelHandler()} />
      </div>
    )
  }
}

OutServiceAreaPopup.propTypes = {
  currentCustomer: PropTypes.shape({}).isRequired,
  locationActions: PropTypes.shape({}).isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(withNavigate(OutServiceAreaPopup))
