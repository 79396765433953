/**
 * Initial requirement
 * - Tally need to refresh price, distance, transitTime when the following factor change
 *  - vehicle type
 *  - time type
 *  - locations
 *  - optimize route
 *  - return discount
 */
import React from 'react'
import PropTypes from 'prop-types'
import { Utils } from 'utils/Utils'
import { TallyUtils } from 'utils/booking/TallyUtils'
import { FULL_DAY } from 'constants/bookingConstants'
import _ from 'lodash'
import I18n from 'i18n/i18n'

const LONG_HYPHEN = '&mdash;'
class TallyBar extends React.PureComponent {
  componentDidUpdate(prevProps) {
    const { toggleTallyBar, timeType } = this.props
    const { price, distance, transitTime } = this.props
    const { price: prevPrice, distance: prevDistance, transitTime: prevTransitTime } = prevProps

    if ((price !== prevPrice || prevPrice)
      && (distance !== prevDistance || timeType === FULL_DAY)) {
      toggleTallyBar()
    }
  }

  render() {
    const {
      // price = LONG_HYPHEN,
      // distance = LONG_HYPHEN,
      // transitTime = LONG_HYPHEN,
      countryLanguage,
      currency,
      visibilitySetting,
      priceLabel,
      waitingAnimation,
      showTallyData
    } = this.props
    let { price, distance, transitTime } = this.props
    // we always show long hyphen if transitTime is null. if it for the case of transit time setting is disabled
    transitTime = transitTime !== null ? transitTime : LONG_HYPHEN

    const hasData = price !== null && distance !== null && transitTime !== null
    const showTally = waitingAnimation ? showTallyData : hasData

    price = +price === 0 ? LONG_HYPHEN : price
    distance = +distance === 0 ? LONG_HYPHEN : distance

    const priceText = _.isFinite(+price)
      ? Utils.formatFee(+price, countryLanguage, currency)
      : price
    const distanceText = _.isFinite(+distance)
      ? `${Utils.formatDistance(TallyUtils.roundUp(distance), countryLanguage)} ${I18n.t('webapp.booking.km')}`
      : distance
    const transitTimeText = _.isFinite(+transitTime)
      ? Utils.convertSecondsToTimeString(+transitTime)
      : transitTime

    /* eslint-disable react/no-danger */
    return (
      <div className="tally-bar-container mt15 mr10 ml10">
        {showTally && (
          <div className="tally-bar Radius-Default pt5 pr5 pb5 pl5 flex flex-row">
            {
              visibilitySetting.price && priceText
                ? (
                  <div className="tally-bar-row-item flex flex-column">
                    <div className="value" dangerouslySetInnerHTML={{ __html: priceText }} />
                    <div className="text">
                      {priceLabel || I18n.t('webapp.tally_bar.price')}
                    </div>
                  </div>
                )
                : null
            }
            {
              visibilitySetting.distance && distanceText
                ? (
                  <div className="tally-bar-row-item flex flex-column">
                    <div className="value" dangerouslySetInnerHTML={{ __html: distanceText }} />
                    <div className="text">
                      {I18n.t('webapp.tally_bar.distance')}
                    </div>
                  </div>
                )
                : null
            }
            {
              visibilitySetting.transitTime && transitTimeText
                ? (
                  <div className="tally-bar-row-item flex flex-column">
                    <div className="value" dangerouslySetInnerHTML={{ __html: transitTimeText }} />
                    <div className="text">
                      {I18n.t('webapp.tally_bar.transit')}
                    </div>
                  </div>
                )
                : null
            }
          </div>
        )
        }
      </div>
    )
  }
}

TallyBar.propTypes = {
  price: PropTypes.number,
  distance: PropTypes.number,
  transitTime: PropTypes.number,
  countryLanguage: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
  visibilitySetting: PropTypes.shape({}),
  priceLabel: PropTypes.string,
  showTallyData: PropTypes.bool,
  toggleTallyBar: PropTypes.func,
  waitingAnimation: PropTypes.bool,
  timeType: PropTypes.string,
}

TallyBar.defaultProps = {
  price: null,
  distance: null,
  transitTime: null,
  visibilitySetting: {
    price: true,
    distance: true,
    transitTime: true,
  },
  priceLabel: null,
  showTallyData: true,
  toggleTallyBar: () => undefined,
  waitingAnimation: false,
  timeType: ''
}

export default TallyBar
