// FOR ACTIONS
export const UPDATE_CURRENT_POPUP_ID = 'UPDATE_CURRENT_POPUP_ID'
export const UPDATE_SUB_CURRENT_POPUP_ID = 'UPDATE_SUB_CURRENT_POPUP_ID'
export const UPDATE_INFO_ASSIGN_DRIVER_POPUP = 'UPDATE_INFO_ASSIGN_DRIVER_POPUP'
export const ADD_POPUP_CONTENT = 'ADD_POPUP_CONTENT'

// FOR POPUP IDS (DOM)
export const POPUP_ID_LOCATIONS = 'locations-popup'
export const POPUP_ID_SELECT_CONTACT = 'batch-select-contact-popup'
export const POPUP_ID_ADD_CONTACT = 'batch-add-contact-popup'
export const POPUP_ID_SAVED_BOOKINGS = 'saved-bookings-popup'
export const POPUP_ID_SERVICE_TYPE_COMING_SOON = 'service-type-coming-soon-popup'
export const POPUP_ID_INVALID_DISCOUNT_CODES = 'invalid-discount-codes-popup'
export const POPUP_ID_SERVICE_TYPE_POPUP_EYE = 'service-type-popup-eye'
export const POPUP_ID_EXTRA_SERVICE_POPUP_EYE = 'extra-service-popup-eye'
export const POPUP_ID_FULL_DAY_S1 = 'vehicle-type-full-day-s1-popup'
export const POPUP_ID_FULL_DAY_S2 = 'vehicle-type-full-day-s2-popup'
export const POPUP_ID_CUSTOMER_DND_POPUP_EYE = 'customer-dnd-popup-eye'
export const POPUP_ID_OUT_OF_SERVICE = 'out-of-service-popup'
export const POPUP_PICKUP_TIME = 'pickup-time-popup'
export const POPUP_PICKUP_TIME_WARNING = 'pickup-time-warning-popup'
export const POPUP_ID_EXTEND_SERVICE_AREA = 'extend-service-area-popup'
export const POPUP_ID_COD_INVOICE_FEE = 'cod-invoice-fees-'
export const POPUP_ID_TOO_MUCH_DROPOFF = 'too-much-dropoff-popup'
export const POPUP_ID_EXCEL_TOO_MUCH_DESTINATIONS = 'too-much-destinations-popup'
export const POPUP_ID_EXCEL_TOO_MUCH_CHARACTERS = 'too-much-characters-popup'
export const POPUP_ID_EXCEL_WRONG_EZ = 'wrong-ez-file-for-individual-popup'
export const POPUP_ID_EXCEL_WRONG_EZ_BIZ = 'wrong-ez-file-for-business-popup'
export const POPUP_ID_POD_COD_POPOVER = 'pod-cod-popover'
export const POPUP_ID_POD_COD_INDIVIDUAL = 'pod-cod-individual'
export const POPUP_ID_POD_COD_NOT_ENOUGH_DROPOFF = 'pod-cod-not-enough-drop-off'
export const POPUP_POD_COD = 'popup-cod'
export const POPUP_ID_LONG_HAUL_DROP_OFF = 'vehicle-type-long-haul-do-popup'
export const POPUP_ID_LONG_HAUL_PICKUP = 'vehicle-type-long-haul-pk-popup'
export const POPUP_ID_PREVIEW_STEP_1_BACK = 'preview-step-1-back-popup'
export const POPUP_ID_PREVIEW_STEP_2_BACK = 'preview-step-2-back-popup'
export const POPUP_ID_CHANGE_TO_SCHEDULE = 'change-longhaul-to-schedule-popup'
export const POPUP_ID_SELECT_MAPPING = 'batch-select-mapping-popup'
export const POPUP_ID_CHANGE_INFO_AFTER_ASSIGNED_DRIVER = 'change-assigned-driver-popup'
export const SHOW_DATE_PICKER_BY_BOOKING = 'show-date-picker-by-booking'
export const SHOW_QUICK_CHOICE_BY_BOOKING = 'show-quick-choice-by-booking'
export const POPUP_ID_TRANSIT_TIME_EST = 'transit-time-estimate-popup'
export const POPUP_ID_SELECT_PER_LOCATION = 'popup-selection-per-location'
export const POPUP_ID_EXCEL_NOT_LOGIN = 'popup-ez-file-not-login'

export const POPUP_LOCATION_NOTE = 'popup-location-note-detail'

// FOR BATCH
export const BATCH_POPUP_ID_OUT_OF_SERVICE = 'batch-popup-id-out-of-service'
export const BATCH_POPUP_ID_EXTEND_SERVICE_AREA = 'batch-popup-id-extend-service-area'
export const POPUP_MAPPING_DATA_CONFIRM = 'batch-popup-mapping-data-confirm'
export const POPUP_ID_DRAFT_BOOKINGS = 'draft-bookings-popup'
export const POPUP_ID_DRAFT_BATCHES = 'draft-batches-popup'
export const POPUP_ID_SAVED_BATCHES = 'saved-bookings-popup'

// FOR POPUP TYPES
export const POPUP_TYPE_SERVICE_TYPE_POPUP_EYE = 'st_pe'
export const POPUP_TYPE_EXTRA_POPUP_EYE = 'es_pe'
export const POPUP_TYPE_EXTRA_POPUP_EYE_PACKAGE = 'es_pk_pe'
export const POPUP_TYPE_EXTRA_POPUP_EYE_FULL_DAY = 'es_fd_pe'
export const POPUP_TYPE_EXTRA_POPUP_OPTIONS = 'es_po'
export const POPUP_TYPE_EXTRA_POPUP_EYE_LONG_HAUL = 'es_lh_pe'
export const POPUP_TYPE_VEHICLE_TYPE_FULL_DAY_S1 = 'vt_fd_s1'
export const POPUP_TYPE_VEHICLE_TYPE_FULL_DAY_S2 = 'vt_fd_s2'
export const POPUP_VEHICLE_TYPE_LONG_HAUL_DROP_OFF = 'vt_lh_do'
export const POPUP_VEHICLE_TYPE_LONG_HAUL_DOCUMENT_RETURN = 'vt_lh_dr'
export const POPUP_VEHICLE_TYPE_SFPOD = 'vt_sf_pod'
export const POPUP_VEHICLE_TYPE_LONG_HAUL_EXTEND_TIME = 'vt_rb_wt_lh'
export const POPUP_VEHICLE_TYPE_OPTIMIZE_ROUTE = 'vt_or_pe'
export const POPUP_VEHICLE_TYPE_ROUND_TRIP = 'vt_rt_pe'
export const POPUP_VEHICLE_TYPE_VEHICLE_DIMENSION_POPUP_EYE = 'vt_vd_pe'
export const POPUP_TYPE_NON_FULL_DAY_ETA = 'ar_non_fd_ts_est'
export const POPUP_TYPE_FULL_DAY_ETA = 'ar_fd_ts_est'
export const POPUP_TYPE_SAVER_EXPLANATION = 'st_sbe_pop'

// FOR POPUP ITEM TYPE
export const ITEM_TYPE_SERVICE_TYPE_PE_TITLE = 'st_pe_title'
export const ITEM_TYPE_SERVICE_TYPE_PE_VT_VD_TITLE = 'st_pe_vt_vd_title'
export const ITEM_TYPE_SERVICE_TYPE_PE_CONTENT = 'st_pe_content'
export const ITEM_TYPE_SERVICE_TYPE_PE_SIZE_TEXT = 'st_pe_size_text'
export const ITEM_TYPE_SERVICE_TYPE_PE_WEIGHT_TEXT = 'st_pe_weight_text'
export const ITEM_TYPE_EXTRA_PE_TITLE = 'es_pe_title'
export const ITEM_TYPE_EXTRA_PE_SUBTITLE = 'es_pe_subtitle'
export const ITEM_TYPE_EXTRA_PE_CONTENT = 'es_pe_content'
export const ITEM_TYPE_EXTRA_PE_CONTENT_LAST = 'es_pe_content_last'
export const ITEM_TYPE_EXTRA_PE_CONTENT_FINAL = 'es_pe_content_final'
export const ITEM_TYPE_EXTRA_PO_TITLE = 'es_po_title'
export const ITEM_TYPE_EXTRA_PO_CONTENT_CHECKBOX = 'es_po_content_checkbox'
export const ITEM_TYPE_VEHICLE_TYPE_FD_TITLE = 'vt_fd_title'
export const ITEM_TYPE_VEHICLE_TYPE_FD_SUBTITLE = 'vt_fd_subtitle'
export const ITEM_TYPE_VEHICLE_TYPE_FD_CONTENT = 'vt_fd_content'
export const ITEM_TYPE_VEHICLE_TYPE_FD_CONTENT_LONG = 'vt_fd_content_long'
export const ITEM_TYPE_VEHICLE_TYPE_FD_CONTENT_SMALL = 'vt_fd_content_small'
export const ITEM_TYPE_VEHICLE_TYPE_OUT_OF_SERVICE_CONTENT = 'vt_oos_content'
export const ITEM_TYPE_VEHICLE_TYPE_OUT_OF_SERVICE_CONTENT_SMALL = 'vt_oos_content_small'
export const ITEM_TYPE_VEHICLE_TYPE_OUT_OF_SERVICE_TITLE = 'vt_oos_title'
export const ITEM_TYPE_AREA_CHANGE_TO_SCHEDULE_CONTENT = 'ar_cts_content'
export const ITEM_TYPE_AREA_OUT_OF_SERVICE_TITLE = 'ar_oos_title'
export const ITEM_TYPE_AREA_OUT_OF_SERVICE_CONTENT = 'ar_oos_content'
export const ITEM_TYPE_AREA_OUT_OF_SERVICE_CONTENT_SMALL = 'ar_oos_content_small'
export const ITEM_TYPE_VEHICLE_TYPE_LH_DOC_RETURN_TITLE = 'vt_lh_dr_title'
export const ITEM_TYPE_VEHICLE_TYPE_LH_DOC_RETURN_CONTENT = 'vt_lh_dr_content'
export const ITEM_TYPE_VEHICLE_TYPE_SFPOD_TITLE = 'vt_sf_pod_title'
export const ITEM_TYPE_VEHICLE_TYPE_SFPOD_CONTENT = 'vt_sf_pod_content'
export const ITEM_TYPE_EXTEND_TIME_LONG_HAUL_TYPE_PE_TITLE = 'vt_rb_lh_pe_title'
export const ITEM_TYPE_EXTEND_TIME_LONG_HAUL_TYPE_PE_CONTENT = 'vt_rb_lh_pe_content'
export const ITEM_TYPE_EXTEND_TIME_LONG_HAUL_TYPE_PE_CONTENT_SMALL = 'vt_rb_lh_pe_content_small'
export const ITEM_TYPE_VEHICLE_TYPE_VEHICLE_DIMENSION_TITLE = 'vt_vd_pe_title'
export const ITEM_TYPE_VEHICLE_TYPE_VEHICLE_DIMENSION_SIZE_TEXT = 'vt_vd_pe_size_text'
export const ITEM_TYPE_VEHICLE_TYPE_VEHICLE_DIMENSION_WEIGHT_TEXT = 'vt_vd_pe_weight_text'
export const ITEM_TYPE_FULL_DAY_ETA_TITLE = 'ar_fd_ts_est_title'
export const ITEM_TYPE_FULL_DAY_ETA_CONTENT = 'ar_fd_ts_est_content'
export const ITEM_TYPE_NON_FULL_DAY_ETA_TITLE = 'ar_non_fd_ts_est_title'
export const ITEM_TYPE_NON_FULL_DAY_ETA_CONTENT = 'ar_non_fd_ts_est_content'

// FOR POPUP LTL
export const POPUP_OUT_OF_SERVICE_LTL = 'out-of-service-ltl-popup'
export const BUTTON_CLOSURE_FAVORITE = 'button-closure-favorite'
export const BUTTON_BOOK_AGAIN_ERROR = 'button-book-again-error'
export const BUTTON_BOOKMARK_LCL = 'button-lcl-favorite'
export const ADDRESSES_MODAL = 'button-closure-addresses-modal'
export const ATTACHMENTS_MODAL = 'button-closure-attachments-modal'
export const DRIVER_NOTE_MODAL = 'button-closure-driver-note-modal'
export const NOTE_MODAL = 'button-closure-note-modal'
export const SIGNATURES_MODAL = 'button-closure-signatures-modal'
export const RATE_DRIVER_MODAL = 'button-closure-rate-driver-modal'
export const DOCS_COD_MODAL = 'button-closure-docs-cod-modal'
export const DOCS_POD_MODAL = 'button-closure-pod-modal'
export const PROOF_OF_DELIVERY_MODAL = 'button-closure-goods-modal'
export const CANCEL_BOOKING_MODAL = 'open-cancel-popup'
export const BUTTON_FAV_DRIVER = 'a-add-to_favorite'

// FOR POPUP PTL
export const POPUP_PRICE = 'button-closure-price-modal'

// FOR POPUP CUSTOM REIMBURSEMENT
export const ITEM_TYPE_CR_PE_TITLE = 'cr_pe_title'
export const ITEM_TYPE_CR_PE_SUBTITLE = 'cr_pe_subtitle'
export const ITEM_TYPE_CR_PE_CONTENT = 'cr_pe_content'
export const ITEM_TYPE_CR_PE_CONTENT_LAST = 'cr_pe_content_last'
export const ITEM_TYPE_CR_PE_CONTENT_FINAL = 'cr_pe_content_final'

// FOR DRIVER PREFERENCES
export const POPUP_ID_ASSIGN_DRIVERS = {
  popup_eye: {
    schedule_tool: {
      item_type: 'ar_ad_scht_pe',
      item_title: 'ar_ad_scht_pe_title',
      item_content: 'ar_ad_scht_pe_content',
    },
    driver_preferences: {
      item_type: 'ar_ad_dp_pe',
      item_title: 'ar_ad_dp_pe_title',
      item_content: 'ar_ad_dp_pe_content',
    },
  },
  popup: {
    favorite_active: {
      item_type: 'ar_ad_fa',
      item_title: 'ar_ad_fa_title',
      item_content: 'ar_ad_fa_content',
    },
    favorite_schedule: {
      item_type: 'ar_ad_fs',
      item_title: 'ar_ad_fs_title',
      item_content: 'ar_ad_fs_content',
    },
  },
}
export const POPUP_ID_YOUR_RECENT_DRIVERS = 'your_recent_drivers'
export const POPUP_ID_UNVAILABLE_DRIVERS = 'unvailable_driver'
export const POPUP_ID_SWITCH_TAB_DRIVER_PREFERENCE = 'switch_tab_driver_preference'
export const POPUP_ID_SWITCH_TO_SCHEDULE = 'switch_to_schedule'

export const PERCENT = '%'

export const LIMIT_NOTES_CHAR = 1000

export const POPUP_ID_PICKUP_TIME = 'POPUP_ID_PICKUP_TIME'
// for Sub account tag
export const LIST_SORT_SUB_ACCOUNT_TAG = [
  { name: 'webapp.default', value: 'position' },
  { name: 'webapp.a_z', value: 'name' },
  { name: 'webapp.recently_use', value: 'last_used_at' },
]

export const VEHICLES_SELECT_POPUP = 'SMART_PLANNER/VEHICLES_SELECT_POPUP'
export const POPUP_CASHBACK_EXPLAIN = 'POPUP_CASHBACK_EXPLAIN'
export const POPUP_MULTIPLE_CASHBACK_EXPLAIN = 'POPUP_MULTIPLE_CASHBACK_EXPLAIN'
export const UNDO_ALL_POPUP = 'SMART_PLANNER/UNDO_ALL'

export const POP_MOTOR_TYPE = 'Motor'
export const POP_MOTOR_BIKE_TYPE = 'Motorbike'

export const POPUP_DEVINA = 'POPUP_DEVINA'
export const POPUP_CS_HELP = 'POPUP_CS_HELP'
export const POPUP_RATING = 'POPUP_RATING'
export const POPUP_RETRY_ASSIGN_DRIVER = 'POPUP_RETRY_ASSIGN_DRIVER'
export const POPUP_CANCELATION_REASONS = 'POPUP_CANCELATION_REASONS'
export const POPUP_BOOKING_CANCELED = 'POPUP_BOOKING_CANCELED'
export const POPUP_PENALTY_FEE = 'POPUP_PENALTY_FEE'
export const POPUP_CANCEL_BOOKING = 'POPUP_CANCEL_BOOKING'
export const POPUP_SUBMIT_EDITS = 'POPUP_SUBMIT_EDITS'
export const POPUP_BOOKING_RETRY = 'POPUP_BOOKING_RETRY'
export const POPUP_CUSTOMER_CHANGES = 'POPUP_CUSTOMER_CHANGES'
export const POPUP_DELETE_SINGLE_BOOKING = 'delete-single-booking-popup'
export const DRIVER_NOT_AVAILABLE = 'DRIVER_NOT_AVAILABLE'
export const POPUP_CANCEL_CONFIRM = 'POPUP_CANCEL_CONFIRM'
export const INVALID_PAYMENT_POPUP = 'INVALID_PAYMENT_POPUP'

export const POPUP_BANK_TRANSFER = 'POPUP_BANK_TRANSFER'
export const POPUP_PAYMENT_EDIT_BOOKING = 'POPUP_PAYMENT_EDIT_BOOKING'
export const EXPIRED_PAYMENT_POPUP = 'EXPIRED_PAYMENT_POPUP'
export const WAITING_PAYMENT_BEFORE_EDIT_POPUP = 'WAITING_PAYMENT_BEFORE_EDIT_POPUP'
export const CANCEL_PAYMENT_MODAL = 'CANCEL_PAYMENT_MODAL'

export const DEVINA_POPUP_TYPE = {
  NOT_SHOW: 'not_show',
  NORMAL: 'normal',
  EXTEND_TIME: 'extend_time',
  CS_CANCEL_NORMAL: 'cs_cancel_normal',
  CS_CANCEL_EXTEND_TIME: 'cs_cancel_extend_time',
  CUSTOMER_CANCEL_NORMAL: 'customer_cancel_normal',
}

export const POPOVER_PAYMENT_METHOD = 'POPOVER_PAYMENT_METHOD'
export const POPOVER_ADDITIONAL_INFO = 'POPOVER_ADDITIONAL_INFO'

export const POPUP_CHANGE_CONFIRM = 'POPUP_CHANGE_CONFIRM'