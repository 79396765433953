// UTILS
// API
import BatchesAPI from 'api/batches'
import BookingAPI from 'api/bookings'
// ACTIONS
// COMPONENTS
// CONSTANTS
import { FULL_DAY, STATUS_ASSIGNING_DRIVER_TIMEOUT, STATUS_LOCATING_DRIVER } from 'constants/bookingConstants'
import _ from 'lodash'
import { selectedBatchActionsCreators } from 'store/toolkit/selectedBatch/selectedBatch.reducer'
import { hideLoading } from 'assets/javascripts/webapp-v2/common'
// ASSETS

export const updateBatchBookings = (bookings) =>
  selectedBatchActionsCreators.updateBatchBookings({
    bookings,
  })

export const updateBatchBookingAttrs = (attrs) =>
  selectedBatchActionsCreators.updateBatchBookingAttrs({
    attrs,
  })

export const getBooking = (bookingID, currentCustomer, callback) => () => {
  BookingAPI.get(bookingID, currentCustomer, (response) => {
    callback(response.object)
  })
}

export const favorite =
  (booking, saveBooking, currentCustomer, callback = () => {}) =>
  () => {
    BookingAPI.favoriteBooking(booking.id, saveBooking, callback, true)
  }

export const retryBookingBatch =
  (bookingID, options = {}) =>
  async (dispatch) => {
    const params = {
      id: bookingID,
      assign_driver_booking_attributes: options.driver_id
        ? {
            driver_id: options.driver_id,
            fleet_partner_id: options.fleet_partner_id,
          }
        : undefined,
      driver_preference_type: options.driver_preference_type || undefined,
    }
    const res = await BookingAPI.retry(params)
      dispatch(
        selectedBatchActionsCreators.updateBatchBookingAttrs({
          attrs:{
          batch_tracking_token: options.booking.batch_tracking_token,
          status: STATUS_LOCATING_DRIVER,
        },
        })
      )
    hideLoading()
    return res
  }

export const updateBookingPickupTime =
  (bookingID, pickupTime, currentCustomer, callback = () => {}) =>
  () => {
    BatchesAPI.updateTimeForOutOfDateBooking(bookingID, pickupTime, currentCustomer, (response) => {
      callback(response)
    })
  }

export const confirmReimbursements = async (batchId, bookings) => {
  const params = { id: batchId }
  const needConfirmedBookings = _.filter(bookings, { need_confirmation: true })
  params.bookings = needConfirmedBookings.map((booking) => {
    const { reimbursementInfo = {} } = booking
    const { location_reimbursements: locationReimbursements = [] } = reimbursementInfo

    const locationsAttributes = []
    const reimbursementsAttributes = []
    const bookingParams = { id: _.toInteger(booking.id), time_type: booking.time_type }
    if (booking.time_type === FULL_DAY) {
      locationReimbursements.forEach((locationReimbursement) => {
        const tolls = _.get(locationReimbursement, 'tolls', [])
        const parking = _.get(locationReimbursement, 'parking', [])
        const waitingTime = _.get(locationReimbursement, 'waiting_time', [])
        const custom = _.get(locationReimbursement, 'custom', [])

        const listReimbursement = tolls.concat(parking, waitingTime, custom)
        listReimbursement.forEach((reimbursement) => {
          reimbursementsAttributes.push({
            id: reimbursement.id,
            is_confirmed: reimbursement.is_confirmed,
          })
        })
      })
      bookingParams.reimbursements_attributes = reimbursementsAttributes
    } else {
      locationReimbursements.forEach((locationReimbursement) => {
        const tolls = _.get(locationReimbursement, 'tolls[0]')
        const parking = _.get(locationReimbursement, 'parking[0]')
        const waitingTime = _.get(locationReimbursement, 'waiting_time[0]')
        const hasTolls = !_.isEmpty(tolls)
        const hasParking = !_.isEmpty(parking)
        const hasWaitingTime = !_.isEmpty(waitingTime)

        const custom = _.get(locationReimbursement, 'custom', [])
        locationsAttributes.push({
          id: locationReimbursement.id,
          tolls_confirmed: hasTolls ? !!tolls.is_confirmed : true,
          parking_confirmed: hasParking ? !!parking.is_confirmed : true,
          waiting_time_confirmed: hasWaitingTime ? !!waitingTime.is_confirmed : true,
        })
        custom.map((reimbursement) =>
          reimbursementsAttributes.push({
            id: reimbursement.id,
            is_confirmed: reimbursement.is_confirmed,
          })
        )
      })
      bookingParams.locations_attributes = locationsAttributes
      bookingParams.reimbursements_attributes = reimbursementsAttributes
    }
    return bookingParams
  })
  const { data } = await BatchesAPI.confirmReimbursements(batchId, params)
  return data
}
